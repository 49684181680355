import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpService) { }

  getReports(){
    return this.http.get('/reports/')
  }

  getPublishedReports(){
    return this.http.get('/reports?status=published')
  }

  getReport(idReport){
    return this.http.get('/reports/'+idReport)
  }
}
