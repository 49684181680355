import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class ReportTemplateService {

  constructor(private http: HttpService) { }

  getReportTemplates(){
    return this.http.get('/report-templates/')
  }

  createReportTemplate(data){
    return this.http.post('/report-templates/', data)
  }

  publishReportTemplate(idReportTemplate){
    return this.http.post('/report-templates/'+ idReportTemplate +'/publish',{})
  }
}