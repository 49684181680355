import { Component, OnInit } from '@angular/core';
import {MenuService} from '../menu.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  MenuList: any[] = []

  constructor(private menu: MenuService,
    private router: Router) { }

  ngOnInit(): void {
  }

  

  async gotoPage(path){
    console.log(path);
    this.router.navigate([path])
  }

}
