import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import {AuthService} from '../auth.service';
import { Router } from '@angular/router';
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: String = "";
  loginError: Boolean = false;
  classLoginButton: String = "button-blue";
  loginButtonText: String = "Inicio";


  constructor(public formBuilder: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private snackBar: MatSnackBar) {
      this.loginForm = formBuilder.group({
        username: ['', Validators.compose([Validators.maxLength(60), Validators.required])],
        password: ['',Validators.compose([Validators.maxLength(60), Validators.required])]
      })
     }

  ngOnInit(): void {
  }

  async login(){
    this.loginButtonText = "";
    this.classLoginButton = "button-blue is-loading";
    console.log('value',this.loginForm.value);
    const loginData = this.loginForm.value
    this.auth.login(loginData)
      .subscribe(
        async Response => {
          console.log('Response');
          console.log(Response);
          this.loginError = false;
          this.errorMessage = '';
          
          this.auth.accessToken = Response.AccessToken
          console.log(Response.userData);
          this.auth.userData = Response.userData

          window.location.reload();
          
          this.classLoginButton = "button-blue";
          this.loginButtonText = "Inicio";
        },
        async ResponseError => {
          console.log(ResponseError.statusCode)
          console.log('error')
          this.loginError = true;
          this.errorMessage = ResponseError.error.message;
          this.showSnackbarAction(this.errorMessage);
          this.classLoginButton = "button-blue";
          this.loginButtonText = "Inicio";
        }
      )

  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

}
