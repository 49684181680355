<div class="warp">
    <div class="group-title">
        <img class="logo-survey" src="../../assets/survey_blue.svg" alt="">
        <h1 class="title-page">Administración de plantillas{{titleComplement}}</h1>
    </div>
    <div class="tabs">
        <ul>
          <!-- <li (click)="selectTag('answers')" [ngClass]="tag_answers_is_active ? 'is-active' : ''"><a>Respuestas</a></li> -->
          <li (click)="selectTag('templates')" [ngClass]="tag_templates_is_active ? 'is-active' : ''"><a>Plantillas</a></li>
          <!-- <li (click)="selectTag('surveys_list')" [ngClass]="tag_surveys_list_is_active ? 'is-active' : ''"><a>Lista de encuestas</a></li> -->
        </ul>
      </div>
    <div *ngIf="viewListTemplates">
        
        <div class="group-page align-right">
            <!-- <button class="button-blue" (click)="goToCreate()" *ngIf="allowCreateTemplate">
                Nueva plantilla
            </button> -->
            <!-- <button class="button-blue" (click)="openModalSurvey(true)" *ngIf="allowCreateTemplate"> -->
            <button class="button-blue" (click)="goToCreate()" *ngIf="allowCreateTemplate">
                Nueva plantilla
            </button>
        </div>
        <div class="group-page" *ngIf="allowViewTemplates">
            <table class="table">
                <thead>
                    <th>Plantilla</th>
                    <!-- <th>Tarea</th> -->
                    <th>Estado</th>
                    <!-- <th></th> -->
                </thead>
                <tbody>
                    <tr *ngFor="let templateItem of template_list">
                        <td>{{ templateItem.name }}</td>
                        <!-- <td>{{ templateItem.task }}</td> -->
                        <td>{{ returnTemplateStatus(templateItem.status) }}</td>
                        <!-- <td class="align-right">
                            <button class="button-blue" (click)="viewTemplateDetail(templateItem.id)">
                                Detalle
                            </button>
                        </td> -->
                    </tr>            
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="viewCreate">
        <div class="group-page">
            <h2>Nombre de la plantilla</h2>
            <input class="input" type="text"
            [(ngModel)]="template_to_create.name" 
            name="templateName" placeholder="Nombre de template_listla plantilla" required="required"/>
        </div>

        <div class="group-page">
            <h2>Fuente de datos</h2>
            <mat-select [(value)]="template_to_create.task_id" placeholder="Fuente de datos" (selectionChange)="selectTask($event.value)">
                <mat-option *ngFor="let task of task_list" [value]="task.id">
                {{ task.name }} ({{ returnTemplateStatus(task.status) }})
                </mat-option>
            </mat-select>
        </div>

        <div *ngFor="let data_item of template_to_create.data_list">
            <div class="group-page">
                <mat-card>
                    <mat-card-header>
                        <mat-card-title>{{data_item.name}}</mat-card-title>
                    </mat-card-header>
                    <mat-card-content>
                        <p>Fuente de datos {{data_item.label}}</p>
                        <p>Tipo de dato {{ data_item.type }}</p>
                        <p>Representación gráfica {{ data_item.graphic_type.label }}</p>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div *ngIf="is_selected_task">
            <div class="separtor-form"></div>

                <div class="group-page">
                    <h2>Etiqueta para representación de dato</h2>
                    <input class="input" type="text"
                    [(ngModel)]="data_to_add.label" 
                    name="templateName" placeholder="Etiqueta para representación de dato" required="required"/>
                </div>

                <div class="group-page">
                    <h2>Area</h2>
                    <mat-select [(value)]="data_to_add.group_area" placeholder="Area" (selectionChange)="selectedGroupArea($event.value)">
                        <mat-option *ngFor="let group_area of selected_task.from_group_area" [value]="group_area.id">
                        {{ group_area.title }}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="group-page">
                    <h2>Dato</h2>
                    <mat-select [(value)]="data_to_add.data" placeholder="Dato" (selectionChange)="selectedData($event.value)">
                        <mat-option *ngFor="let data of data_to_add.data_list" [value]="data">
                        {{ data.label }}
                        </mat-option>
                    </mat-select>
                </div>

                <div class="group-page">
                    <h2>Representación de datos</h2>
                    <div class="div-left-content">
                        <mat-radio-group 
                            class="radio-group"
                            [(ngModel)]="data_to_add.graphic_type">
                            <mat-radio-button *ngFor="let graphic_type_to_select of data_to_add.graphic_type_list" [value]="graphic_type_to_select.id" class="radio-button">
                                {{graphic_type_to_select.graphic}}
                            </mat-radio-button>
                        </mat-radio-group>
                    </div>
                </div>

                <div class="group-page align-left">
                    <button class="button-green" (click)="addNewData()">
                        Agregar dato
                    </button>
                </div>

            <div class="separtor-form"></div>
        </div>

        <div class="group-button">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="button-blue" (click)="createTemplate()">
                Crear plantilla
            </button>
        </div>
    </div>

</div>
<app-modal-report [showModalSurvey]="showModalSurvey"></app-modal-report>