<div id="report-page" class="warp">
    <div class="group-page">
        <h1 class="title-page">Visualizador de reporte{{titleComplement}}</h1>
    </div>
    <div *ngIf="viewList">
        <div class="group-page" *ngIf="allowViewReports">
            <table class="table">
                <thead>
                    <th>Reporte</th>
                    <th></th>
                </thead>
                <tbody>
                        <tr *ngFor="let report of report_list">
                            <td>{{ report.name }}</td>
                            <td class="align-right">
                                <button class="button-blue" (click)="viewReportData(report.id)">
                                    Ver reporte
                                </button>
                            </td>
                        </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="viewDetail">
        <div class="group-page">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ report_detail.name }}</mat-card-title>
                    <mat-card-subtitle>Datos del reporte</mat-card-subtitle>
                </mat-card-header>
                <!--
                <mat-card-content>
                    <p>Tarea en estado {{ returnTaskStatus(task_detail.status) }}</p>
                    <p>Tarea creada el {{ returnTaskCerationDate(task_detail.created_at) }}</p>
                </mat-card-content>
                -->
            </mat-card>
        </div>
        

        <div *ngFor="let data_graphic of report_detail.report_data_info" class="group-page">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{ data_graphic.name }}</mat-card-title>
                    <mat-card-subtitle>{{ data_graphic.label }}</mat-card-subtitle>
                </mat-card-header>
                    <div *ngIf="data_graphic.graphic_id == 1">
                        <div class="chart">
                            <canvas baseChart
                                [id]="data_graphic.graphic_report_id"
                                [datasets]="data_graphic.barChartData"
                                [labels]="data_graphic.barChartLabels"
                                [options]="data_graphic.barChartOptions"
                                [plugins]="data_graphic.barChartPlugins"
                                [legend]="data_graphic.barChartLegend"
                                [chartType]="data_graphic.barChartType">
                            </canvas>
                        </div>
                    </div>
                    <div *ngIf="data_graphic.graphic_id == 2">
                        <div class="chart">
                            <canvas baseChart
                                [id]="data_graphic.graphic_report_id"
                                [data]="data_graphic.pieChartData"
                                [labels]="data_graphic.pieChartLabels"
                                [chartType]="data_graphic.pieChartType"
                                [options]="data_graphic.pieChartOptions"
                                [plugins]="data_graphic.pieChartPlugins"
                                [colors]="data_graphic.pieChartColors"
                                [legend]="data_graphic.pieChartLegend">
                            </canvas>
                        </div>
                    </div>
                    <div *ngIf="data_graphic.graphic_id == 3">
                        <div style="display: block">
                            <canvas baseChart
                                [id]="data_graphic.graphic_report_id"
                                [data]="data_graphic.doughnutChartData"
                                [labels]="data_graphic.doughnutChartLabels"
                                [chartType]="data_graphic.doughnutChartType"
                                [options]="data_graphic.doughnutChartOptions">
                            </canvas>
                          </div>
                    </div>
                <mat-card-content>
                </mat-card-content>
            </mat-card>
        </div>

        
        <div class="group-page align-right">
            <button class="button-white"
                (click)="goToPrincipal()">
                Regresar
            </button>
        </div>

        <div class="group-page align-right">
            <button class="button-white"
                (click)="generatePDF()">
                PDF
            </button>
        </div>
        <div style="height:0; width:0; overflow:hidden;">
        <!-- <div> -->
            <div *ngFor="let data_graphic of report_detail.report_data_info_pdf">
                <div *ngIf="data_graphic.graphic_id == 1">
                    <canvas baseChart
                        [id]="data_graphic.graphic_report_id"
                        [datasets]="data_graphic.barChartData"
                        [labels]="data_graphic.barChartLabels"
                        [options]="data_graphic.barChartOptions"
                        [plugins]="data_graphic.barChartPlugins"
                        [legend]="data_graphic.barChartLegend"
                        [chartType]="data_graphic.barChartType"
                        width="800"
                        height="400">
                    </canvas>
                </div>
                <div *ngIf="data_graphic.graphic_id == 2">
                    <canvas baseChart
                        [id]="data_graphic.graphic_report_id"
                        [data]="data_graphic.pieChartData"
                        [labels]="data_graphic.pieChartLabels"
                        [chartType]="data_graphic.pieChartType"
                        [options]="data_graphic.pieChartOptions"
                        [plugins]="data_graphic.pieChartPlugins"
                        [colors]="data_graphic.pieChartColors"
                        [legend]="data_graphic.pieChartLegend"
                        width="800"
                        height="400">
                    </canvas>
                </div>
                <div *ngIf="data_graphic.graphic_id == 3">
                    <canvas baseChart
                        [id]="data_graphic.graphic_report_id"
                        [data]="data_graphic.doughnutChartData"
                        [labels]="data_graphic.doughnutChartLabels"
                        [chartType]="data_graphic.doughnutChartType"
                        [options]="data_graphic.doughnutChartOptions"
                        width="800"
                        height="400">
                    </canvas>
                    <!-- width="750"
                        height="375" -->
                </div>
            </div>
        </div> 
    </div>
</div>
