import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-report',
  templateUrl: './modal-report.component.html',
  styleUrls: ['./modal-report.component.css']
})
export class ModalReportComponent implements OnInit {

  @Input() showModalSurvey : Boolean;

  constructor() { }


  ngOnInit(): void {
  }

  active : String = 'is-active';
}
