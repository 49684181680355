<!-- app.component.html -->
<app-login *ngIf="!loggedUser"></app-login>
<mat-drawer-container *ngIf="loggedUser" class="example-container">
  <mat-drawer mode="side" [ngClass]="isOpenSideMenu ?  'is-open' : 'is-close'" opened>
    <app-menu></app-menu>
    <div class="logout-div" [ngClass]="isOpenSideMenu ?  'is-open' : 'is-close' ">
      <span >{{label_name}}</span>
      <li class="logout-li">
        <a class="text-menu" (click)="logout()">Cerrar sesión</a>
      </li>
    </div>
  </mat-drawer>
  <mat-drawer-content [ngClass]="isOpenSideMenu ? 'is-open' :'is-close' ">
    <app-navbar></app-navbar>
    <router-outlet></router-outlet>
  </mat-drawer-content>
</mat-drawer-container>