import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { HomeComponent } from "./home/home.component";
import { UsersComponent } from "./users/users.component";
import { ProfilesComponent } from "./profiles/profiles.component";
import { TaskComponent } from './task/task.component';
import { EnterDataComponent } from './enter-data/enter-data.component';
import { ReportTemplatesComponent } from './report-templates/report-templates.component';
import { ReportsComponent } from './reports/reports.component';

const routes: Routes = [
  { path: "", component: AppComponent, pathMatch: "full" },
  { path: "login", component: LoginComponent, pathMatch: "full" },
  { path: "home", component: HomeComponent, pathMatch: "full" },
  { path: "profiles", component: ProfilesComponent, pathMatch: "full" },
  { path: "users", component: UsersComponent, pathMatch: "full" },
  { path: "tasks", component: TaskComponent, pathMatch: "full" },
  { path: "enter-data", component: EnterDataComponent, pathMatch: "full" },
  { path: "report-templates", component: ReportTemplatesComponent, pathMatch: "full"},
  { path: "reports", component: ReportsComponent, pathMatch: "full"}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
