<nav class="navbar is-transparent">
  
    <div id="navbarSurvey" class="navbar-menu">
      <div class="navbar-start">

        <div class="navbar-item">
            <div class="hamburger-menu" [ngClass]="sideMenuIsOpen ? 'is-open' : 'is-close'" (click)="openSideMenu()">
              <div class="bar top"></div>
              <div class="bar middle"></div>
              <div class="bar bottom"></div>
            </div>
        </div>
      </div>
      <div class="navbar-end">
        <div class="navbar-item has-dropdown is-hoverable">  
            <div class="navbar-item">
                <div class="profile-img-nav">
                </div>
            </div> 
            <li class="list-user">
                <a class="navbar-link">
                  {{label_name}}
                <span>{{ profile_name }}</span>
                </a>
            </li>
          <div class="navbar-dropdown is-boxed">
            <!-- <a class="navbar-item">
            </a> -->
            <!-- <a class="navbar-item">
              Modifiers
            </a> -->
            <!-- <hr class="navbar-divider"> -->
            <a class="navbar-item is-active" (click)="logoutNavbar()">
              Cerrar Sesión
            </a>
            
          </div>
        </div>
      </div>
    </div>
  </nav>