import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(private http: HttpService) { }

  getMenu(){
    return this.http.get('/menu')
  }
  
  get menu() {
    return JSON.parse(localStorage.getItem('menu'))
  }

  set menu(data) {
    localStorage.setItem('menu', JSON.stringify(data))
  }

  get actions() {
    return JSON.parse(localStorage.getItem('actions'))
  }

  set actions(data) {
    localStorage.setItem('actions', JSON.stringify(data))
  }
}