import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { UsersService } from '../users.service';
import { ProfilesService } from '../profiles.service';
import {MenuService} from '../menu.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  titleComplement: String = "";
  viewList: Boolean = true;
  viewDetail: Boolean = false;
  viewCreate: Boolean = false;
  viewEdit: Boolean = false;
  viewDeleteConfirmation: Boolean = false;

  allowCreateUsers: Boolean = false;
  allowViewUsers: Boolean = false;
  allowEditUsers: Boolean = false;
  allowDeleteUser: Boolean = false;

  user_list: any[] = [];
  user_detail: {};
  user_to_create: {};
  user_to_edit: {};
  list_profile_for_user: any[] = [];
  list_profile_for_user_to_create: any[] = [];

  selectedProfileToEdit: String='';
  selectedProfileForUserToCreate: String = '';

  newPasswordForUserToCreate: String = '';

  userForm: FormGroup;
  errorMessage: String = "";

  constructor(public formBuilder: FormBuilder,
    private users: UsersService,
    private menu: MenuService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private profile: ProfilesService) {
    this.userForm = formBuilder.group({
      username: ['', Validators.compose([Validators.maxLength(60), Validators.required])],
      password: ['',Validators.compose([Validators.maxLength(60), Validators.required])]
    })
  }

  ngOnInit(): void {
    this.getusers();

    this.allowCreateUsers = this.allowPerision('f2728a93-df7c-4d31-8cd9-1a43083a09bd');
    this.allowViewUsers = this.allowPerision('709d4e0f-585d-40a2-becb-1ae83abb663a');
    this.allowEditUsers = this.allowPerision('bce40221-5710-41f3-9b6d-6a53db5654d0');
    this.allowDeleteUser = this.allowPerision('9d3bb0e8-c002-4d62-89de-88c3f7d423ad');
  }

  async getusers(){
    this.titleComplement = "";
    this.users.getUsers()
      .subscribe(
        async Response => {
          console.log(Response)
          console.log(Response);
          this.user_list = Response
          console.log(this.user_list);
        },
        async ResponseError => {
          console.log('error al obtener los perfiles')
          this.showSnackbarAction(ResponseError.message)
        }
      )
  }

  async goToCreate(){
    this.titleComplement = "/creación de usuario"
    console.log('viewProfileDetail ')
    this.viewList = false;
    this.viewDetail = false;
    this.viewCreate = true;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;

    this.selectedProfileForUserToCreate = '';

    this.user_to_create = {};

    this.user_to_create['username'] = '';
    this.user_to_create['surnames'] = '';
    this.user_to_create['names'] = '';
    this.user_to_create['email'] = '';

    this.profile.getProfiles()
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
            if(Response.statusCode == 200){
              console.log(Response.data);
              this.list_profile_for_user_to_create = Response.data
              console.log(this.list_profile_for_user_to_create);
            }else{
              console.log('error en el login');
            }
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async viewUserDetail(idUser){
    this.titleComplement = "/detalle de usuario"
    console.log('viewProfileDetail ',idUser)
    this.viewList = false;
    this.viewDetail = true;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.users.getUser(idUser)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          this.user_detail = Response
          console.log(this.user_detail);
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async goToPrincipal(){
    this.titleComplement = ""
    this.viewList = true;
    this.viewDetail = false;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.getusers();
  }

  allowPerision(idPermision){
    let actions = [];
    actions = this.menu.actions

    let finded = actions.find(item => { return item.action_id == idPermision && item.active})

    let value_to_return = false;
    if(finded){
      value_to_return = true;
    }else{
      value_to_return = false;
    }

    return value_to_return;

  }

  openConfirmDeleteUserDialog(idUser) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: ('¿Esta seguro de eliminar este usuario?'),
        buttonText: {
          cancel: 'Cancelar',
          ok: 'Eliminar'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.users.deleteUser(idUser)
        .subscribe(
          async Response => {
            console.log(Response);
            this.showSnackbarAction('Usuario eliminado');
            this.goToPrincipal();
          },
          async ResponseError => {
            console.log('Error al eliminar el perfil')
            this.showSnackbarAction('Error al eliminar el usuario');
          }
        )
      }
    });
  }

  async toEditUserDetail(userId){
    this.titleComplement = "/editar usuario"
    console.log('viewProfileDetail ',userId)
    this.viewList = false;
    this.viewDetail = false;
    this.viewCreate = false;
    this.viewEdit = true;
    this.viewDeleteConfirmation = false;
    this.users.getUser(userId)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response);
          this.user_to_edit = Response
          this.selectedProfileToEdit = this.user_to_edit['profile']['id'];
          console.log(this.user_to_edit);
        },
        async ResponseError => {
          this.showSnackbarAction('Error al obtener el usuario');
        }
      )
      this.profile.getProfiles()
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
            if(Response.statusCode == 200){
              console.log(Response.data);
              this.list_profile_for_user = Response.data
              console.log(this.list_profile_for_user);
            }else{
              console.log('error en el login');
            }
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async goToDetail(){
    this.titleComplement = "/detalle de usuario"
    this.viewList = false;
    this.viewDetail = true;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
  }

  async saveChangesUserDetail(){
    let idUser = this.user_to_edit['id'];
    let dataUser = this.user_to_edit;
    dataUser['profile_id'] = this.selectedProfileToEdit;

    console.log('profile_id:: ', dataUser['profile_id']);

    console.log(dataUser);

    this.users.updateUser(idUser, dataUser)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response);
          this.showSnackbarAction('Usuario editado');
          this.viewUserDetail(idUser);
        },
        async ResponseError => {
          this.showSnackbarAction('Error al editar usuario');
        }
      )
  }

  async createProfile(){
    let dataUser = this.user_to_create;
    dataUser['profile_id'] = this.selectedProfileForUserToCreate;
    dataUser['phone'] = "+573003003001"
    dataUser['password'] = this.newPasswordForUserToCreate;

    

    console.log(dataUser);
    this.users.createUser(dataUser)
      .subscribe(
        async Response => {

          console.log('Perfil creado...');
          console.log(Response);
          this.showSnackbarAction('Usuario '+dataUser['names']+' creado con exito!');
          this.viewUserDetail(Response['id']);
        },
        async ResponseError => {
          console.log('error al crear el perfil');
          this.showSnackbarAction('Error al crear el usuario');
        }
      )
  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }
}

