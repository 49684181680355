import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class TasksService {

  constructor(private http: HttpService) { }

  getTasks(){
    return this.http.get('/tasks/')
  }

  getPublishedTasks(){
    return this.http.get('/tasks?status=published')
  }

  getTask(idTask){
    return this.http.get('/tasks/'+idTask)
  }

  deleteTask(idTask){
    return this.http.delete('/tasks/'+idTask)
  }

  createTask(data){
    return this.http.post('/tasks', data)
  }

  publishTask(idTask){
    return this.http.post('/tasks/'+ idTask +'/publish',{})
  }

  stopTask(idTask){
    return this.http.post('/tasks/'+ idTask +'/stop',{})
  }

}
