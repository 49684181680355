import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpService) { }

  login(data: interfaceDataLogin){
    console.log(data)
    return this.http.post('/auth/login', data, {})
  }

  //    return JSON.parse(localStorage.getItem('access_token'))

  get userData() {
    return localStorage.getItem('user_data')
  }

  set userData(userData) {
    localStorage.setItem('user_data', userData)
  }

  get accessToken() {
    return localStorage.getItem('access_token')
  }

  set accessToken(token) {
    localStorage.setItem('access_token', token)
  }

  get session() {
    return localStorage.getItem('session')
  }

  set session(session) {
    localStorage.setItem('session', session)
  }
}

export interface interfaceDataLogin {
  "username": string;
  "password": string;
}
