<div class="warp">
    <div class="group-page">
        <h1 class="title-page">Administración de tareas{{titleComplement}}</h1>
    </div>
    <div class="tabs">
        <ul>
            <li (click)="selectTag('answers')" [ngClass]="tag_answers_is_active ? 'is-active' : ''"><a>Respuestas</a></li>
            <li (click)="selectTag('templates')" [ngClass]="tag_templates_is_active ? 'is-active' : ''"><a>Plantillas</a></li>
            <li (click)="selectTag('surveys_list')" [ngClass]="tag_surveys_list_is_active ? 'is-active' : ''"><a>Lista de encuestas</a></li>
        </ul>
    </div>
    <div *ngIf="viewList">

        <div class="group-page align-right" *ngIf="viewListTemplates">
            <button class="button-blue" (click)="openModalNewTempateTask()" *ngIf="allowCreateTasks">
                Nueva tarea
            </button>
        </div>

        <div class="group-page align-right" *ngIf="viewAnswersList">
            <button class="button-green" (click)="downloadAnswersList()" *ngIf="allowCreateTasks">
                Exportar
            </button>
        </div>

        <div class="group-page align-right" *ngIf="viewSurveyList">
            <button class="button-blue" (click)="openModalNewTempateTask()" *ngIf="allowCreateTasks">
                Asignar
            </button>
        </div>
        
        <div class="group-page" *ngIf="allowViewTasks">


            <div class="{{viewTaskListId}}" *ngIf="viewListTemplates">
                
                <!-- <div class="columns group-page " *ngFor="let templateGroupItem of task_list"> -->
                    <!--                     <div class="card column is-half" *ngFor="let templateItem of raw_task_list"> -->
                <div class="wrap-card" *ngFor="let templateItem of raw_task_list">
                    <div class="card">
                        <header class="card-header">
                            <p [ngClass]="templateItem.show_body ? 'card-header-title-open':'card-header-title-close'">{{ templateItem.name }}</p>
                            <button class="card-header-icon" aria-label="more options" (click)="showBodyCard(templateItem.id)">
                                <span class="icon">
                                    <i [ngClass]="templateItem.card_icon_up_down"></i>
                                </span>
                            </button>
                        </header>
                        
                        <div class="card-content" [ngClass]="templateItem.show_body ? '':'is-hidden'">
                            <div class="content">
                                <ol>
                                    <li class="text-item-card" *ngFor="let itemQuestion of templateItem.questions">
                                        {{itemQuestion.label}}
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="view-answers-list-id" *ngIf="viewAnswersList">
                <table class="table-survey-style">
                    <thead class="titles">
                        <tr>
                            <th>Survey name <span class="lnr lnr-magnifier search-icon"></span> </th>
                            <th>POS name</th>
                            <th>POS location</th>
                            <th>Assignee</th>
                            <th>DeadLine</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let survey_item of answers_list">
                            <tr >
                                <td> <span [ngClass]="survey_item.show_questions ? 'lnr lnr-chevron-up-circle' : 'lnr lnr-chevron-down-circle'" class="expand-icon" (click)="survey_item.show_questions = !survey_item.show_questions"></span> {{survey_item.name}}</td>
                                <td>{{survey_item.pos_name}}</td>
                                <td>{{survey_item.pos_location}}</td>
                                <td>{{survey_item.assignee}}</td>
                                <td>{{survey_item.deadline}}</td>
                            </tr>
                            <!--
                                *ngIf="survey_item.from_group_area[0].show_questions"
                            -->
                            <tr [ngStyle]="{'display': survey_item.show_questions ? 'table-row' : 'none' }">
                                <td colspan="5">
                                    <div class="div-show-answers" *ngFor="let question of survey_item.questions; ">
                                        <div class="div-question">
                                            <span class="item-number">{{question.number}} .</span>
                                            {{question.label}}
                                        </div>
                                        <div class="div-answer">{{question.answer}}</div>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        
                    </tbody>
                </table>
            </div>

            <div class="view-survey-list-id" *ngIf="this.viewSurveyList">
                <table class="table-survey-style">
                    <thead class="titles">
                        <tr>
                            <th>Survey name <span class="lnr lnr-magnifier search-icon"></span> </th>
                            <th>POS name</th>
                            <th>POS location</th>
                            <th>Assignee</th>
                            <th>DeadLine</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let survey_item of answers_list">
                            <tr >
                                <td>{{survey_item.name}}</td>
                                <td>{{survey_item.pos_name}}</td>
                                <td>{{survey_item.pos_location}}</td>
                                <td>{{survey_item.assignee}}</td>
                                <td>{{survey_item.deadline}}</td>
                            </tr>
                        </ng-container>
                        
                    </tbody>
                </table>
            </div>

            <!--
            <div class="tabs">
                <ul>
                    <li (click)="selectTag('answers')" [ngClass]="tag_answers_is_active ? 'is-active' : ''"><a>Respuestas</a></li>
                    <li (click)="selectTag('templates')" [ngClass]="tag_templates_is_active ? 'is-active' : ''"><a>Plantillas</a></li>
                    <li (click)="selectTag('surveys_list')" [ngClass]="tag_surveys_list_is_active ? 'is-active' : ''"><a>Lista de encuestas</a></li>
                </ul>
            </div>
            <table class="table">
                <thead>
                    <th>Tarea</th>
                    <th>Estado</th>
                    <th></th>
                </thead>
                <tbody>
                        <tr *ngFor="let taskItem of task_list">
                            <td>{{taskItem.name}}</td>
                            <td>{{ returnTaskStatus(taskItem.status) }}</td>
                            <td class="align-right">
                                <button class="button-blue" (click)="viewTaskDetail(taskItem.id)">
                                    Detalle
                                </button>
                            </td>
                        </tr>            
                </tbody>
            </table>
            -->
        </div>
    </div>

    <div *ngIf="viewCreate">
        <div class="group-page">
            <h2>Nombre de la tarea</h2>
            <input class="input" type="text"
            [(ngModel)]="task_to_create.name" 
            name="taskName" placeholder="Nombre de la tarea" required="required"/>
        </div>

        <div *ngFor="let area of from_group_area;let indexOfArea=index;">
            <div class="group-from-area">

                <div class="group-page-actions align-right">
                    <button class="custom-delete-btn" (click)="deleteArea(indexOfArea)">
                        Eliminar área
                    </button>
                </div>

                <label>Titulo de área</label>
                <input class="input"
                    type="text"
                    [(ngModel)]="area.title"
                    required="required"/>
                <label>Descripción de área</label>
                <textarea class="input"
                    type="text"
                    [(ngModel)]="area.description"
                    required="required">
                </textarea>

                <label>Preguntas</label>

                <div class="separtor-form"></div>

                <div *ngFor="let question of area.questions;let indexOfQuestion=index;">

                    <div class="group-page-actions align-right">
                        <button class="custom-delete-btn" (click)="removeQuestionOfArea(area, indexOfQuestion)">
                            Eliminar pregunta
                        </button>
                    </div>
                    
                    <label>Enunciado de pregunta</label>

                    <input class="input"
                        type="text"
                        [(ngModel)]="question.label"
                        placeholder="Pregunta"
                        required="required"/>
                    
                    <label>Tipo de respuesta</label>

                    <mat-select [(value)]="question.type" placeholder="Tipo de pregunta">
                        <mat-option *ngFor="let question_type of question_types" [value]="question_type.value">
                          {{question_type.label}}
                        </mat-option>
                    </mat-select>

                    <div *ngIf="question.type == 'checklist' || question.type == 'select' || question.type == 'radiogroup'">
                        <div class="group-page">
                            <table style="width: 100%;">
                                <tr *ngFor="let option of question.options;let indexOfOption=index;">
                                    <td style="vertical-align: baseline;">
                                        <input class="input"
                                            type="text"
                                            [(ngModel)]="option.label"
                                            placeholder="Opcion"
                                            required="required"/>
                                    </td>
                                    <td style="text-align: end;vertical-align: baseline;">
                                        <button class="custom-delete-btn" (click)="removeOptionOfQuestion(question,indexOfOption)">
                                            Eliminar opción
                                        </button>
                                    </td>
                                </tr>
                            </table>
                            
                        </div>
                        <div class="group-page align-left">
                            <button class="button-green" (click)="addOptionsToQuestion(question)">
                                Agregar opcion
                            </button>
                        </div>
                    </div>

                    <div class="separtor-form"></div>
                </div>

                <div class="group-page align-left">
                    <button class="button-green" (click)="addQuestionToArea(area)">
                        Agregar pregunta
                    </button>
                </div>

                
            </div>
        </div>

        <div class="group-page align-left">
            <button class="button-green" (click)="addNewArea()">
                Agregar area
            </button>
        </div>

        <div class="separtor-form"></div>

        <div class="group-button">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="button-blue" (click)="createTask()">
                Crear tarea
            </button>
        </div>
    </div>

    <div *ngIf="viewDetail">
        <div class="group-page">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{task_detail.name}}</mat-card-title>
                    <mat-card-subtitle>Detalles de la tarea</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>Tarea en estado {{ returnTaskStatus(task_detail.status) }}</p>
                    <p>Tarea creada el {{ returnTaskCerationDate(task_detail.created_at) }}</p>
                </mat-card-content>
            </mat-card>
        </div>
        

        <div *ngFor="let group_area of task_detail.from_group_area" class="group-page">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{group_area.title}}</mat-card-title>
                    <mat-card-subtitle>{{group_area.description}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div *ngFor="let question of group_area.questions">
                        <p>{{question.label}}</p>
                        <input *ngIf="question.type == 'text'"
                            class="input"
                            type="text"
                            required="required"/>
                        
                        <input *ngIf="question.type == 'phone'"
                            class="input"
                            type="tel"
                            required="required"/>

                        <input *ngIf="question.type == 'date'"
                            class="input"
                            type="date"
                            required="required"/>
                        
                        <input *ngIf="question.type == 'time'"
                            class="input"
                            type="time"
                            required="required"/>
                        
                        <input *ngIf="question.type == 'number'"
                            class="input"
                            type="number"
                            required="required"/>
                        
                        <textarea *ngIf="question.type == 'textarea'"
                            class="input"
                            required="required">
                        </textarea>

                        <input *ngIf="question.type == 'email'"
                            class="input"
                            type="email"
                            required="required"/>
                        
                        <div *ngIf="question.type == 'photo'" class="div-center-content">
                            <button class="button-green">
                                Capturar imagen(Beta)
                            </button>
                        </div>

                        <div *ngIf="question.type == 'gps'" class="div-center-content">
                            <button class="button-green">
                                Capturar ubicación(Beta)
                            </button>
                        </div>

                        <div *ngIf="question.type == 'checklist'" class="div-left-content">
                            <section *ngFor="let option of question.options">
                                <p><mat-checkbox>{{option.label}}</mat-checkbox></p>
                            </section>

                        </div>

                        <mat-select *ngIf="question.type == 'select'" placeholder="Seleccione una opción" >
                            <mat-option *ngFor="let option of question.options" [value]="option.id">
                              {{option.label}}
                            </mat-option>
                        </mat-select>

                        <div *ngIf="question.type == 'radiogroup'" class="div-left-content">
                            <mat-radio-group 
                                class="radio-group">
                                <mat-radio-button *ngFor="let option of question.options" [value]="option.id" class="radio-button">
                                    {{option.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <br>
                        <br>
                    </div>
                </mat-card-content>
            </mat-card>

        </div>

        
        <div class="group-page align-right">
            <button class="button-white"
                (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="custom-delete-btn"
                *ngIf="allowDeleteTasks && task_detail.status != 'PUBLISHED' && task_detail.status != 'DELETED'"
                (click)="openConfirmDeleteProfileDialog(task_detail.id)">
                Eliminar
            </button>
            <button class="custom-pub-btn"
                *ngIf="allowPublishTasks && task_detail.status != 'PUBLISHED' && task_detail.status != 'DELETED'"
                (click)="publishTask(task_detail.id)">
                Publicar
            </button>
            <button class="custom-pub-btn"
                *ngIf="allowStopTasks && task_detail.status == 'PUBLISHED'"
                (click)="stopTask(task_detail.id)">
                Pausar
            </button>
        <!--  
            <button class="button-blue"
                *ngIf="allowEditTasks && task_detail.status != 'PUBLISHED' && task_detail.status != 'DELETED'">
                Editar
            </button>
        -->
        </div>
    </div>
    <!--
    <div *ngIf="viewEdit">
        <div class="group-page">
            <h2>Nombre de perfil</h2>
            <input class="input" type="text"
            [(ngModel)]="profile_to_edit.name" 
            name="profileName" placeholder="Nombre del perfil" required="required"/>
        </div>
        <h2>Permisos</h2>
        <div class="group-page" *ngFor="let action of profile_to_edit.actions">
            <input type="checkbox"
                [checked]="action.active"
                [(ngModel)]="action.active"
            />
            <label >{{action.label}}</label>
        </div>
        <div class="group-page align-right">
            <button class="button-white" (click)="goToDetail()">
                Cancelar
            </button>
            <button class="button-blue" (click)="saveChangesProfileDetail()">
                Guardar Cambios
            </button>
        </div>
    </div>
    -->
</div>
<app-new-template-task (reloadTasks)="getTasks()"></app-new-template-task>
