<nav [ngClass]="sideMenuIsOpen ? 'is-open' : 'is-close' ">
    <div class="box">
        <aside class="menu">
            <ul class="menu-list">
                <div class="menu-item">
                    <div class="hamburger-menu" [ngClass]="sideMenuIsOpen ? 'is-open' : 'is-close' " (click)="closeSideMenu()">
                        <div class="bar top"></div>
                        <div class="bar middle"></div>
                        <div class="bar bottom"></div>
                    </div>
                </div>
                <div class="logo-sidebar">
                    <img class="logo-img" src="../../assets/logo.svg" alt="">
                    <li class="has-text">Shelf Recognition</li>
                </div>
                
                <li class="has-text-grey-dark"
                    *ngFor="let menuItem of MenuList" 
                    class="item-active-{{menuItem.selected}} show-menu-{{menuItem.active}}"
                    (click)="selectItem(menuItem)"
                    routerLink="{{menuItem.path}}">
                    <a class="text-menu"
                        (click)="selectItem(menuItem)"
                        routerLink="{{menuItem.path}}">
                        <div class="arrow"></div>
                        <img class="logo-menu" src="../../assets/{{menuItem.icon_menu}}" alt="">
                        {{menuItem.label}}
                    </a>
                </li>
            </ul>
        </aside>
    </div>
    <!--     <div class="loading_menu" *ngIf="loading_menu"> -->
    <div class="loading_menu"  *ngIf="loading_menu">
        <div class="spinner"></div>
    </div>
</nav>