import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpService) { }

  getUsers(){
    return this.http.get('/users', {});
  }

  getUser(userId){
    return this.http.get('/users/'+userId, {});
  }

  createUser(userData){
    return this.http.post('/users', userData);
  }

  updateUser(userId,userData){
    return this.http.put('/users/'+userId, userData);
  }

  deleteUser(userId){
    return this.http.delete('/users/'+userId);
  }
  
}
