<div class="modal " [ngClass]="showModalSurvey ? 'is-open': 'is-close'">
    <div class="modal-background"></div>
    <div class="modal-panel">
        <div class="wrap">
            <div class="close-button" (click)="showModalSurvey = false">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1495_19068)">
                    <path d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z" fill="#48536A"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1495_19068">
                    <rect width="36" height="36" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    
            </div>
            <div class="head-modal">
                <h1 class="title-modal">Agregar encuesta</h1>
                <label class="subtitle-gray">Titulo</label>
                <input class="input" type="text">
                <label class="subtitle-gray">Descripcion</label>
                <input class="input" type="text">
            </div>
            <hr/>
            <div class="question">
                <label class="subtitle-gray">Pregunta</label>
                <input class="input" type="text">
                <label class="subtitle-gray">Tipo de pregunta</label>
                <div class="select">
                    <select>
                      <option>Seleccionar tipo</option>
                      <option>Opcion uno</option>
                    </select>
                  </div>

            </div>
            <hr>
            <div class="add-more">
                <p >+</p>
            </div>
            
            <div class="group-button">
                <button class="button-white" (click)="showModalSurvey = false">Cancelar</button>
                <button class="button-blue" >Guardar</button>
            </div>
        </div>
    </div>
</div>