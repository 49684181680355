import { Component, OnInit } from '@angular/core';
import { MenuService } from '../menu.service';
import { ReportsService } from '../reports.service';

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';

import { MultiDataSet, Label } from 'ng2-charts';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import jsPDF from "jspdf";

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css']
})
export class ReportsComponent implements OnInit {
  titleComplement: String = "";

  viewList: Boolean = true;
  viewDetail: Boolean = false;

  allowViewReports: Boolean = false;

  report_list : any[] = []
  report_detail: {};

  constructor(private reports: ReportsService,
    private menu: MenuService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getReports();
    this.allowViewReports = this.allowPerision('3231417e-7faf-45bc-a8e6-eefff7ac072a');
  }

  async getReports(){
    this.titleComplement = ""
    this.report_list = []
    this.reports.getReports()
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.report_list = Response.data
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener los reportes');
      }
    )
  }

  async viewReportData(idReport){
    this.titleComplement = "/Detalle de reporte"
    console.log('viewTaskDetail ',idReport)
    this.viewList = false;
    this.viewDetail = true;
    this.reports.getReport(idReport)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response.data);
          this.report_detail = Response.data
          this.report_detail['report_data_info_pdf'] = JSON.parse(JSON.stringify(this.report_detail['report_data_info']));
          this.setGraphicDataValue()
          console.log(this.report_detail);
        },
        async ResponseError => {
          console.log('error al obtener el detalle del reporte')
          console.log(ResponseError);
          this.showSnackbarAction('Error al obtener el detalle del reporte');

        }
      )
  }
  
  async generatePDF(){

    // let doc = new jsPDF({
    //   unit: 'px', // set the units of measurement to px
    //   format: 'letter', // set the 'paper' size
    //   userUnit: 72, // set the DPI here. Web uses 72 but you can change to 150 or 300
    // });

    const amount_elements =this.report_detail['report_data_info'].length;

    let doc = new jsPDF();
    
    var img_cont = 20
    const image_by_page = 2
    const amount_page = Math.ceil(amount_elements/image_by_page)
    var current_image_by_page = 0
    var page_count = 1
    var title_report = this.report_detail['name']

    doc.setFontSize(10);
    var with_text_page = doc.getTextWidth('pagina '+page_count+' de '+amount_page);

    doc.text('pagina '+page_count+' de '+amount_page,210-(with_text_page+7), 8 );

    doc.setFontSize(25);
    var with_text_page = doc.getTextWidth(title_report);

    doc.text(title_report,((210/2)-(with_text_page/2)), img_cont );
    
    img_cont = img_cont + 6;

    doc.line(7, img_cont, 203, img_cont)

    img_cont = img_cont + 15;


    this.report_detail['report_data_info'].map(function(item){
      
      let canvas = document.getElementById(item['graphic_report_id'] + '_pdf')  as HTMLCanvasElement;
      let dataURL = canvas.toDataURL('image/jpg', 1.0)

      var canvas_width = canvas.width;
      var canvas_height = canvas.height;
      let image_relation = 4.44

      let img_width = canvas_width/image_relation
      let img_height = canvas_height/image_relation

      console.log('item[\'graphic_report_id\']',item['graphic_report_id'])
      console.log('current_image_by_page ', current_image_by_page)
      console.log('current_image_by_page < image_by_page', current_image_by_page < image_by_page);
      if(current_image_by_page < image_by_page){

        doc.setFontSize(15);
        doc.text(item['name'],15, img_cont);
        img_cont = img_cont + 5;

        doc.setFontSize(11);
        doc.text(item['label'],15, img_cont);
        img_cont = img_cont + 5;

        doc.addImage(dataURL, "JPEG", 15, img_cont, img_width, img_height);

        current_image_by_page = current_image_by_page + 1
        img_cont = img_cont + 115

      }else{
        doc.addPage()
        page_count = page_count +1;
        img_cont = 30;

        doc.setFontSize(10);
        var with_text_page = doc.getTextWidth('pagina '+page_count+' de '+amount_page);

        doc.text('pagina '+page_count+' de '+amount_page,210-(with_text_page+7), 8 );

        doc.setFontSize(15);
        doc.text(item['name'],15, img_cont);
        img_cont = img_cont + 5;

        doc.setFontSize(11);
        doc.text(item['label'],15, img_cont);
        img_cont = img_cont + 5;

        doc.addImage(dataURL, "JPEG", 15, img_cont, img_width, img_height);
        
        img_cont = img_cont + 120

        current_image_by_page = 1
      }

      

    })

    doc.save('test.pdf');
  }


  async setGraphicDataValue(){

    this.report_detail['report_data_info'] = this.report_detail['report_data_info'].map(function(item, index){
      console.log('item')
      console.log(item)
      console.log('index')
      console.log('graphic_report_id_'+index)
      item['graphic_report_id'] = 'graphic_report_id_'+index

      if(item['graphic_id'] == 1){
        const barChartOptions: ChartOptions = {
          responsive: true,
          // We use these empty structures as placeholders for dynamic theming.
          scales: { xAxes: [{}], yAxes: [{}] },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
            }
          }
        };
        const barChartLabels: Label[] = item['data']['labels'];
        const barChartType: ChartType = 'bar';
        const barChartLegend = true;
        const barChartPlugins = [pluginDataLabels];
        console.log(item['data']['label'])
        console.log(item['data']['data'])
      
        const barChartData: ChartDataSets[] = item['data']['data'];

        item['barChartOptions'] = barChartOptions 
        item['barChartLabels'] = barChartLabels
        item['barChartType'] = barChartType
        item['barChartLegend'] = barChartLegend
        item['barChartPlugins'] = barChartPlugins
        item['barChartData'] = barChartData

      }else if(item['graphic_id'] == 2){
        const pieChartOptions: ChartOptions = {
          responsive: true,
          legend: {
            position: 'top',
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                const label = ctx.chart.data.labels[ctx.dataIndex];
                return label;
              },
            },
          }
        };
        const pieChartLabels: Label[] = item['data']['labels'];
        const pieChartData: number[] = item['data']['data'];
        const pieChartType: ChartType = 'pie';
        const pieChartLegend = true;
        const pieChartPlugins = [pluginDataLabels];
        const pieChartColors = [
          {
            backgroundColor: item['data']['colors'],
          },
        ];

        item['pieChartOptions'] = pieChartOptions 
        item['pieChartLabels'] = pieChartLabels
        item['pieChartData'] = pieChartData
        item['pieChartType'] = pieChartType
        item['pieChartLegend'] = pieChartLegend
        item['pieChartPlugins'] = pieChartPlugins
        item['pieChartColors'] = pieChartColors

      }else if(item['graphic_id'] == 3){
        const doughnutChartLabels: Label[] = item['data']['labels'];
        const doughnutChartData: MultiDataSet = item['data']['data'];
        const doughnutChartType: ChartType = 'doughnut';

        const doughnutChartOptions: ChartOptions = {
          responsive: true
        };

        item['doughnutChartOptions'] = doughnutChartOptions 
        item['doughnutChartLabels'] = doughnutChartLabels 
        item['doughnutChartData'] = doughnutChartData
        item['doughnutChartType'] = doughnutChartType

      }else{

      }
      return item

    })
    this.setGraphicDataValuePDF()

  }


  async setGraphicDataValuePDF(){

    this.report_detail['report_data_info_pdf'] = this.report_detail['report_data_info_pdf'].map(function(item, index){
      console.log('item')
      console.log(item)
      console.log('index')
      console.log('graphic_report_id_'+index)

      item['graphic_report_id'] = 'graphic_report_id_'+index+'_pdf'

      if(item['graphic_id'] == 1){
        const barChartOptions: ChartOptions = {
          responsive: false,
          // We use these empty structures as placeholders for dynamic theming.
          scales: { xAxes: [{}], yAxes: [{}] },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
            }
          }
        };
        const barChartLabels: Label[] = item['data']['labels'];
        const barChartType: ChartType = 'bar';
        const barChartLegend = true;
        const barChartPlugins = [pluginDataLabels];
        console.log(item['data']['label'])
        console.log(item['data']['data'])
      
        const barChartData: ChartDataSets[] = item['data']['data'];

        item['barChartOptions'] = barChartOptions 
        item['barChartLabels'] = barChartLabels
        item['barChartType'] = barChartType
        item['barChartLegend'] = barChartLegend
        item['barChartPlugins'] = barChartPlugins
        item['barChartData'] = barChartData

      }else if(item['graphic_id'] == 2){
        const pieChartOptions: ChartOptions = {
          responsive: false,
          legend: {
            position: 'top',
          },
          plugins: {
            datalabels: {
              formatter: (value, ctx) => {
                const label = ctx.chart.data.labels[ctx.dataIndex];
                return label;
              },
            },
          }
        };
        const pieChartLabels: Label[] = item['data']['labels'];
        const pieChartData: number[] = item['data']['data'];
        const pieChartType: ChartType = 'pie';
        const pieChartLegend = true;
        const pieChartPlugins = [pluginDataLabels];
        const pieChartColors = [
          {
            backgroundColor: item['data']['colors'],
          },
        ];

        item['pieChartOptions'] = pieChartOptions 
        item['pieChartLabels'] = pieChartLabels
        item['pieChartData'] = pieChartData
        item['pieChartType'] = pieChartType
        item['pieChartLegend'] = pieChartLegend
        item['pieChartPlugins'] = pieChartPlugins
        item['pieChartColors'] = pieChartColors

      }else if(item['graphic_id'] == 3){
        const doughnutChartLabels: Label[] = item['data']['labels'];
        const doughnutChartData: MultiDataSet = item['data']['data'];
        const doughnutChartType: ChartType = 'doughnut';

        const doughnutChartOptions: ChartOptions = {
          responsive: false
        };

        item['doughnutChartOptions'] = doughnutChartOptions 
        item['doughnutChartLabels'] = doughnutChartLabels 
        item['doughnutChartData'] = doughnutChartData
        item['doughnutChartType'] = doughnutChartType

      }else{

      }
      return item

    })

  }

  async goToPrincipal(){
    this.titleComplement = ""
    this.viewList = true;
    this.viewDetail = false;
    this.getReports();
  }


  allowPerision(idPermision){
    let actions = [];
    actions = this.menu.actions

    let finded = actions.find(item => { return item.action_id == idPermision && item.active})

    let value_to_return = false;
    if(finded){
      value_to_return = true;
    }else{
      value_to_return = false;
    }

    return value_to_return;
  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

}
