import { Component, OnInit } from '@angular/core';
import { ProfilesService } from '../profiles.service';
import {MenuService} from '../menu.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';



@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})


export class ProfilesComponent implements OnInit {
  titleComplement: String = "";
  viewList: Boolean = true;
  viewDetail: Boolean = false;
  viewCreate: Boolean = false;
  viewEdit: Boolean = false;
  viewDeleteConfirmation: Boolean = false;

  allowCreateProfiles: Boolean = false;
  allowViewProfiles: Boolean = false;
  allowEditProfiles: Boolean = false;
  allowDeleteProfiles: Boolean = false;


  profile_list: any[] = [];
  profile_detail: {};
  profile_to_create: {};
  profile_to_edit: {};
  

  constructor(private profile: ProfilesService,
    private menu: MenuService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }
  //usar una tabla normal
  ngOnInit(): void {
    this.getProfiles();

    this.allowCreateProfiles = this.allowPerision('4fc45307-cb4d-47e5-848c-3d95e30bf122');
    this.allowViewProfiles = this.allowPerision('4809322e-7458-4408-a405-2a60958055aa');
    this.allowEditProfiles = this.allowPerision('606952b5-29e2-49d1-9309-ca568db659c4');
    this.allowDeleteProfiles = this.allowPerision('e449b5ea-d067-47ce-8673-4e7406f85add');

    console.log(this.allowCreateProfiles);
    console.log(this.allowViewProfiles);
    console.log(this.allowEditProfiles);
    console.log(this.allowDeleteProfiles);
  }

  async getProfiles(){
    this.titleComplement = ""
    this.profile.getProfiles()
    .subscribe(
      async Response => {
        console.log(Response.statusCode)
          if(Response.statusCode == 200){
            console.log(Response.data);
            this.profile_list = Response.data
            console.log(this.profile_list);
          }else{
            console.log('error al obtener los perfiles');
          }
      },
      async ResponseError => {
        console.log('error al obtener los perfiles')
      }
    )
  }

  async viewProfileDetail(idProfile){
    this.titleComplement = "/detalle de perfil"
    console.log('viewProfileDetail ',idProfile)
    this.viewList = false;
    this.viewDetail = true;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.profile.getProfile(idProfile)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
            if(Response.statusCode == 200){
              console.log(Response.data);
              this.profile_detail = Response.data
              console.log(this.profile_detail);
            }else{
              console.log('error en el login');
            }
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async toEditProfileDetail(idProfile){
    this.titleComplement = "/editar perfil"
    console.log('viewProfileDetail ',idProfile)
    this.viewList = false;
    this.viewDetail = false;
    this.viewCreate = false;
    this.viewEdit = true;
    this.viewDeleteConfirmation = false;
    this.profile.getProfile(idProfile)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
            if(Response.statusCode == 200){
              console.log(Response.data);
              this.profile_to_edit = Response.data
              console.log(this.profile_detail);
            }else{
              console.log('error en el login');
            }
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async saveChangesProfileDetail(){
    let idProfile = this.profile_to_edit['id'];
    let dataProfile = this.profile_to_edit;

    dataProfile['actions'] = dataProfile['actions'].map(item => {
      let item_tmp = {};

      item_tmp['action_id'] = item['action_id']
      item_tmp['label'] = item['label']
      item_tmp['active'] = item['active']

      return item_tmp;

    })

    console.log(dataProfile);

    this.profile.updateProfile(idProfile, dataProfile)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
            if(Response.statusCode == 200){
              console.log(Response.data);
              this.showSnackbarAction('Perfil editado con exito');
              this.viewProfileDetail(idProfile);
            }else{
              console.log('error al editar usuario');
              this.showSnackbarAction('Error al editar usuario');
            }
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async goToCreate(){
    this.titleComplement = "/creación de perfil"
    console.log('viewProfileDetail ')
    this.viewList = false;
    this.viewDetail = false;
    this.viewCreate = true;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;

    this.profile_to_create = {};

    this.profile_to_create['name'] = '';

    this.profile.getActions()
      .subscribe(
        async Response => {
          console.log(Response);
          let actions_to_new_profile = Response.map(item => {
            let item_tmp = {};
            item_tmp = item;
            item_tmp['active'] = false;
            return item_tmp
          })
          this.profile_to_create['actions'] = actions_to_new_profile;
          console.log(this.profile_detail);
        },
        async ResponseError => {
          console.log('error al obtener el detalle del perfil')
        }
      )
  }

  async createProfile(){
    let dataProfile = this.profile_to_create;

    dataProfile['actions'] = dataProfile['actions'].map(item => {
      let item_tmp = {};

      item_tmp['action_id'] = item['id']
      item_tmp['label'] = item['label']
      item_tmp['active'] = item['active']

      return item_tmp;

    })

    console.log(dataProfile);

    this.profile.createProfile(dataProfile)
      .subscribe(
        async Response => {

          console.log('Perfil creado...');
          console.log(Response);
          this.showSnackbarAction('Perfil '+dataProfile['name']+' creado con exito!');
          this.viewProfileDetail(Response['id']);
        },
        async ResponseError => {
          console.log('error al crear el perfil');
        }
      )
  }

  async goToPrincipal(){
    this.titleComplement = ""
    this.viewList = true;
    this.viewDetail = false;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.getProfiles();
  }

  async goToDetail(){
    this.titleComplement = "/detalle de perfil"
    this.viewList = false;
    this.viewDetail = true;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
  }

  allowPerision(idPermision){
    let actions = [];
    actions = this.menu.actions

    let finded = actions.find(item => { return item.action_id == idPermision && item.active})

    let value_to_return = false;
    if(finded){
      value_to_return = true;
    }else{
      value_to_return = false;
    }

    return value_to_return;

  }

  openConfirmDeleteProfileDialog(idProfile) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: ('¿Esta seguro de eliminar el perfil?'),
        buttonText: {
          cancel: 'Cancelar',
          ok: 'Eliminar'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.profile.deleteProfile(idProfile)
        .subscribe(
          async Response => {
            console.log(Response);
            this.showSnackbarAction('Perfil eliminado');
            this.goToPrincipal();
          },
          async ResponseError => {
            console.log('Error al eliminar el perfil')
            this.showSnackbarAction('Error al eliminar el perfil');
          }
        )
      }
    });
  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }
}