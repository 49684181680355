<div class="warp">
    <div class="group-page">
        <h1 class="title-page">Ingreso de datos{{titleComplement}}</h1>
    </div>
    <div *ngIf="viewList">
        <div class="group-page">
            <table class="table">
                <thead>
                    <th>Tarea</th>
                    <th>Estado</th>
                    <th></th>
                </thead>
                <tbody>
                        <tr *ngFor="let taskItem of task_list">
                            <td>{{taskItem.name}}</td>
                            <td>{{ returnTaskStatus(taskItem.status) }}</td>
                            <td class="align-right">
                                <button class="button-blue" (click)="viewTaskDetail(taskItem.id)">
                                    Ingresar datos
                                </button>
                            </td>
                        </tr>            
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="viewDetail">
        <div class="group-page">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{task_detail.name}}</mat-card-title>
                    <mat-card-subtitle>Detalles de la tarea</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <p>Tarea en estado {{ returnTaskStatus(task_detail.status) }}</p>
                    <p>Tarea creada el {{ returnTaskCerationDate(task_detail.created_at) }}</p>
                </mat-card-content>
            </mat-card>
        </div>
        

        <div *ngFor="let group_area of task_detail.from_group_area" class="group-page">
            <mat-card>
                <mat-card-header>
                    <mat-card-title>{{group_area.title}}</mat-card-title>
                    <mat-card-subtitle>{{group_area.description}}</mat-card-subtitle>
                </mat-card-header>
                <mat-card-content>
                    <div *ngFor="let question of group_area.questions">
                        <p>{{question.label}}</p>

                        <input *ngIf="question.type == 'text'"
                            [(ngModel)]="question.value"
                            class="input"
                            type="text"
                            required="required"/>
                        
                        <input *ngIf="question.type == 'phone'"
                            [(ngModel)]="question.value"
                            class="input"
                            type="tel"
                            required="required"/>

                        <input *ngIf="question.type == 'date'"
                            [ngModel]="question.value | date:'yyyy-MM-dd' : 'UTC'"
                            (ngModelChange)="question.value = $event"
                            class="input"
                            type="date"
                            required="required"/>
                        
                        <input *ngIf="question.type == 'time'"
                            [(ngModel)]="question.value"
                            class="input"
                            type="time"
                            required="required"/>
                        
                        <input *ngIf="question.type == 'number'"
                            [(ngModel)]="question.value"
                            class="input"
                            type="number"
                            required="required"/>
                        
                        <textarea *ngIf="question.type == 'textarea'"
                            [(ngModel)]="question.value"
                            class="input"
                            required="required">
                        </textarea>

                        <input *ngIf="question.type == 'email'"
                            [(ngModel)]="question.value"
                            class="input"
                            type="email"
                            required="required"/>
                        
                        <div *ngIf="question.type == 'photo'" class="div-center-content">
                            <button class="button-green">
                                Capturar imagen(Beta)
                            </button>
                        </div>

                        <div *ngIf="question.type == 'gps'" class="div-center-content">
                            <button class="button-green">
                                Capturar ubicación(Beta)
                            </button>
                        </div>

                        <div *ngIf="question.type == 'checklist'" class="div-left-content">
                            <section *ngFor="let option of question.options">
                                <p><mat-checkbox [(ngModel)]="option.value ">{{option.label}}</mat-checkbox></p>
                            </section>

                        </div>

                        <mat-select *ngIf="question.type == 'select'" [(value)]="question.value" placeholder="Seleccione una opción" >
                            <mat-option *ngFor="let option of question.options" [value]="option.id">
                              {{option.label}}
                            </mat-option>
                        </mat-select>

                        <div *ngIf="question.type == 'radiogroup'" class="div-left-content">
                            <mat-radio-group 
                                class="radio-group"
                                [(ngModel)]="question.value">
                                <mat-radio-button *ngFor="let option of question.options" [value]="option.id" class="radio-button">
                                    {{option.label}}
                                </mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <br>
                        <br>
                    </div>
                </mat-card-content>
            </mat-card>

        </div>

        
        <div class="group-button">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="button-blue" (click)="saveData()">
                Guardar datos
            </button>
        </div>
    </div>
</div>
