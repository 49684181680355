import { Component, OnInit } from '@angular/core';
import {MenuService} from '../menu.service';
import { ReportTemplateService } from '../report-template.service';
import { TasksService } from '../tasks.service';
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-report-templates',
  templateUrl: './report-templates.component.html',
  styleUrls: ['./report-templates.component.css']
})
export class ReportTemplatesComponent implements OnInit {
  tag_answers_is_active: Boolean = false;
  tag_templates_is_active: Boolean = true;
  tag_surveys_list_is_active: Boolean = false;

  titleComplement: String = "";
  viewListTemplates: Boolean = true;
  viewDetail: Boolean = false;
  viewCreate: Boolean = false;
  viewEdit: Boolean = false;
  viewDeleteConfirmation: Boolean = false;

  allowCreateTemplate: Boolean = false;
  allowViewTemplates: Boolean = false;
  allowEditTemplate: Boolean = false;
  allowDeleteTemplate: Boolean = false;
  allowPublishTemplate: Boolean = false;
  allowStopTemplate: Boolean = false;

  template_list: any[] = [];
  template_detail: {};
  template_to_create: {};

  task_list: any[] = [];

  is_selected_task: Boolean = false;
  selected_task: {};
  data_to_add: {};

  template_to_edit: {};

  template_status: {};

  graphic_options_by_input_type: {};
  grafic_type: any[] = [];

  showModalSurvey: Boolean = false;

  constructor(private reportTemplate:ReportTemplateService,
    private menu: MenuService,
    private task: TasksService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getReportTemplates();

    this.allowCreateTemplate = this.allowPerision('bcc53441-8857-4d1f-a209-e2c2cafcb784');
    this.allowViewTemplates = this.allowPerision('389ba85b-8b27-40f2-9188-688f95c1c24e');
    this.allowEditTemplate = this.allowPerision('5c20a8cc-da78-4d3a-a768-aa776cf5fcdd');
    this.allowDeleteTemplate = this.allowPerision('2d8effe9-0b9e-43a7-9887-ac7cbb31163c');
    this.allowPublishTemplate = this.allowPerision('61cb4298-fef6-4ded-8ae2-64845d600256');

    this.setTemplateStatus();
    this.setGraphicOptions();
    this.setGraphicType();
  }

  selectTag(selectTag){
    if (selectTag == 'answers'){
      this.tag_answers_is_active = true;
      this.tag_templates_is_active = false;
      this.tag_surveys_list_is_active = false;

      this.viewListTemplates = false;

    }
    
    if (selectTag == 'templates'){
      this.tag_answers_is_active = false;
      this.tag_templates_is_active = true;
      this.tag_surveys_list_is_active = false;

      this.viewListTemplates = true;

    }

    if (selectTag == 'surveys_list'){
      this.tag_answers_is_active = false;
      this.tag_templates_is_active = false;
      this.tag_surveys_list_is_active = true;

      this.viewListTemplates = false;

    }
  }

  setTemplateStatus(){
    this.template_status = {
      CREATED: 'creada',
      PUBLISHED: 'publicada',
      STOPPED: 'detenida',
      DELETED: 'eliminada'
    }
  }
  setGraphicType(){
    this.grafic_type = [
      {id:1,graphic:'Barras'},
      {id:2,graphic:'Tortas'},
      {id:3,graphic:'Dona'},
      {id:4,graphic:'Matrices (beta)'},
      {id:5,graphic:'N-gram word cloud (beta)'},
      {id:6,graphic:'Lineas (beta)'}
    ]
  }

  setGraphicOptions(){
    this.graphic_options_by_input_type = {
      text: [
        {id:1,graphic:'Barras'},
        {id:2,graphic:'Tortas'},
        {id:3,graphic:'Dona'},
        {id:4,graphic:'Matrices (beta)'},
        {id:5,graphic:'N-gram word cloud (beta)'},
        {id:6,graphic:'Lineas (beta)'}
      ],
      phone: [],
      date: [],
      time: [],
      number: [
        {id:1,graphic:'Barras'},
        {id:2,graphic:'Tortas'},
        {id:3,graphic:'Dona'},
        {id:4,graphic:'Matrices (beta)'},
        {id:6,graphic:'Lineas (beta)'}
      ],
      textarea: [
        {id:1,graphic:'Barras'},
        {id:2,graphic:'Tortas'},
        {id:3,graphic:'Dona'},
        {id:4,graphic:'Matrices (beta)'},
        {id:5,graphic:'N-gram word cloud (beta)'},
        {id:6,graphic:'Lineas (beta)'}
      ],
      email: [],
      photo: [],
      gps: [],
      checklist: [
        {id:1,graphic:'Barras'},
        {id:2,graphic:'Tortas'},
        {id:3,graphic:'Dona'},
        {id:4,graphic:'Matrices (beta)'},
        {id:5,graphic:'N-gram word cloud (beta)'},
        {id:6,graphic:'Lineas (beta)'}
      ],
      select: [
        {id:1,graphic:'Barras'},
        {id:2,graphic:'Tortas'},
        {id:3,graphic:'Dona'},
        {id:4,graphic:'Matrices (beta)'},
        {id:5,graphic:'N-gram word cloud (beta)'},
        {id:6,graphic:'Lineas (beta)'}
      ],
      radiogroup: [
        {id:1,graphic:'Barras'},
        {id:2,graphic:'Tortas'},
        {id:3,graphic:'Dona'},
        {id:4,graphic:'Matrices (beta)'},
        {id:5,graphic:'N-gram word cloud (beta)'},
        {id:6,graphic:'Lineas (beta)'}
      ]

    }
  }

  returnTemplateStatus(status){
    return this.template_status[status];
  }

  async getReportTemplates(){
    this.titleComplement = ""
    this.template_list = []
    this.reportTemplate.getReportTemplates()
    .subscribe(
      async Response => {
        console.log(Response.data);
         this.template_list  = Response.data
        //  const tmp_template_list = Response.data
        //  var cont = 0;
        //  var list_par = [];

        //  for (let item of tmp_template_list) {

        //   if(cont==0){
        //     list_par = []
        //   }

        //   item['show_body'] = false;

        //   list_par.push(item);

        //   if(cont==1){
        //     this.template_list.push(list_par);
        //   }

        //   if (cont==1){
        //     cont = 0
        //   }else{
        //     cont = cont +1;
        //   }

        // }
        // console.log(this.template_list);
        //this.template_list
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener las tareas');
      }
    )
  }

  async goToCreate(){
    this.titleComplement = "/creación de plantilla"
    console.log('create template')
    this.viewListTemplates = false;
    this.viewDetail = false;
    this.viewCreate = true;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;

    this.is_selected_task = false;

    this.selected_task = {};

    this.getTasks();

    this.template_to_create = {};

    this.template_to_create['name'] = '';
    this.template_to_create['data_list'] = [];
  }

  async getTasks(){
    this.task_list = []
    this.task.getTasks()
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.task_list = Response.data;
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener las tareas');
      }
    )
  }

  async goToPrincipal(){
    this.titleComplement = ""
    this.viewListTemplates = true;
    this.viewDetail = false;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.is_selected_task = false;
    this.selected_task = {};
    this.data_to_add = {};

    this.getReportTemplates();
  }

  async selectTask(taskSelectedId){
    this.task.getTask(taskSelectedId)
      .subscribe(
        async Response => {
          this.is_selected_task = true;
          this.selected_task = Response.data;
          console.log('this.selected_task: ',this.selected_task);


          this.data_to_add = {};
          this.data_to_add['label'] = '';
          this.data_to_add['data_list'] = [];
          this.data_to_add['graphic_type_list'] = [];
          this.data_to_add['graphic_type'] = {};
        },
        async ResponseError => {
          console.log('error al obtener el detalle de la tarea')
          console.log(ResponseError);
          this.showSnackbarAction('Error al obtener el detalle de la tarea');

        }
      )
  }

  selectedGroupArea(idSelectedGroupArea){
    console.log('idSelectedGroupArea: ',idSelectedGroupArea);

    const selected_group_area = this.selected_task['from_group_area'].find(item => item.id == idSelectedGroupArea);
    
    this.data_to_add['id_group_area'] = selected_group_area['id'];
    this.data_to_add['data_list'] = selected_group_area['questions'];
    this.data_to_add['group_area_label'] = selected_group_area['title']

    this.data_to_add['graphic_type_list'] = [];
  }

  selectedData(selectedData){
    console.log(selectedData);
    var data_type_selected = selectedData['type'];
    var data_id_selected = selectedData['id'];
    var data_label_selected = selectedData['label'];
    
    this.data_to_add['id_question'] = data_id_selected;
    this.data_to_add['type'] = data_type_selected;
    this.data_to_add['question_label'] = data_label_selected;
    this.data_to_add['graphic_type_list'] = this.graphic_options_by_input_type[selectedData['type']];
    console.log('this.data_to_add[\'graphic_type_list\']',this.data_to_add['graphic_type_list']);
  }

  addNewData(){

    var graphic_type_id = this.data_to_add['graphic_type'];
    var graphic_label = this.grafic_type.find(item => item.id == graphic_type_id)
    var group_area_label = this.data_to_add['group_area_label'];
    var question_label = this.data_to_add['question_label']

    var tmp_data_to_add = {
      name: this.data_to_add['label'],
      label: group_area_label +'/'+ question_label,
      type: this.data_to_add['type'],
      id_group_area: this.data_to_add['id_group_area'],
      id_question: this.data_to_add['id_question'],
      graphic_type: {id: graphic_type_id, label: graphic_label['graphic']}
    }

    this.template_to_create['data_list'].push(tmp_data_to_add);
    this.data_to_add = {};
  }

  async createTemplate(){
    console.log(this.template_to_create);

    this.reportTemplate.createReportTemplate(this.template_to_create)
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.showSnackbarAction('Plantilla creada');
        let report_template_id = Response.data['id'];
        //this.viewTaskDetail(task_id);
      },
      async ResponseError => {
        console.log('error al crear el report template');
        console.log(ResponseError);
        this.showSnackbarAction('error al crear el report template');
      }
    )
  }

  allowPerision(idPermision){
    let actions = [];
    actions = this.menu.actions

    let finded = actions.find(item => { return item.action_id == idPermision && item.active})

    let value_to_return = false;
    if(finded){
      value_to_return = true;
    }else{
      value_to_return = false;
    }

    return value_to_return;

  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

  openModalSurvey(){
    this.showModalSurvey = !this.showModalSurvey;
  }

}
