import { Component, OnInit, ViewChild } from '@angular/core';
import {MenuService} from '../menu.service';
import {TasksService} from '../tasks.service';
import {EnterDataService} from '../enter-data.service';

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { NewTemplateTaskComponent } from "./new-template-task/new-template-task.component";


@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.css']
})
export class TaskComponent implements OnInit {
  titleComplement: String = "";
  viewList: Boolean = true;
  viewDetail: Boolean = false;
  viewCreate: Boolean = false;
  viewEdit: Boolean = false;
  viewDeleteConfirmation: Boolean = false;

  allowCreateTasks: Boolean = false;
  allowViewTasks: Boolean = false;
  allowEditTasks: Boolean = false;
  allowDeleteTasks: Boolean = false;
  allowPublishTasks: Boolean = false;
  allowStopTasks: Boolean = false;

  tag_answers_is_active: Boolean = true;
  tag_templates_is_active: Boolean = false;
  tag_surveys_list_is_active: Boolean = false;

  viewAnswersList: Boolean = true;
  viewListTemplates: Boolean = false;
  viewSurveyList: Boolean = false;



  answers_list: any[] = [];
  task_list: any[] = [];
  raw_task_list: any[] = [];
  task_detail: {};
  task_to_create: {};
  task_to_edit: {};
  from_group_area: any[] = [];

  question_types: any[] = [];

  task_status: {}

  seq_area_id = 0;
  
  months: any[] = [];

  @ViewChild(NewTemplateTaskComponent) newTemplateTaskChild;

  viewTaskListId = "view-task-list-id";
  viewAnswersListId = "view-answers-list-id"
  viewSurveyListId = "view-survey-list-id"

  constructor(private task: TasksService,
    private menu: MenuService,
    private data: EnterDataService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getTasks();
    this.getAnswersList();

    this.allowCreateTasks = this.allowPerision('99772656-4686-4e68-9365-f1787be201ff');
    this.allowViewTasks = this.allowPerision('3b9862e1-76aa-491b-83a1-033708cd26c7');
    this.allowEditTasks = this.allowPerision('a45eae58-7d53-4449-af7c-33b609836b26');
    this.allowDeleteTasks = this.allowPerision('7cd6237b-29ad-4c2b-87bd-bb62fc7bf8bb');
    this.allowPublishTasks = this.allowPerision('8279a3f8-9061-4fd3-819d-2b2baad2691c');
    this.allowStopTasks = this.allowPerision('ae3fb95f-9608-48b3-a719-d2465627f434');

    this.setQuestionTypes();
    this.setTaskStatus();
    this.setMonths();

    this.initNewTask();

  }


  selectTag(selectTag){
    if (selectTag == 'answers'){
      this.tag_answers_is_active = true;
      this.tag_templates_is_active = false;
      this.tag_surveys_list_is_active = false;

      this.viewAnswersList = true;
      this.viewListTemplates = false;
      this.viewSurveyList = false;

    }
    
    if (selectTag == 'templates'){
      this.tag_answers_is_active = false;
      this.tag_templates_is_active = true;
      this.tag_surveys_list_is_active = false;

      this.viewAnswersList = false;
      this.viewListTemplates = true;
      this.viewSurveyList = false;

    }

    if (selectTag == 'surveys_list'){
      this.tag_answers_is_active = false;
      this.tag_templates_is_active = false;
      this.tag_surveys_list_is_active = true;

      this.viewAnswersList = false;
      this.viewListTemplates = false;
      this.viewSurveyList = true;

    }
  }

  async setTaskStatus(){
    this.task_status = {
      CREATED: 'creada',
      PUBLISHED: 'publicada',
      STOPPED: 'detenida',
      DELETED: 'eliminada'
    }
  }

  returnTaskStatus(status){
    return this.task_status[status];
  }

  returnTaskCerationDate(creation_timestamp){

    let unix_timestamp = creation_timestamp
    let date_to_return = new Date(unix_timestamp * 1000);

    return  date_to_return.getDate()+
    ' de '+this.months[date_to_return.getMonth()]+
    ' de '+date_to_return.getFullYear()+
    ' '+ (date_to_return.getHours() > 9 ? date_to_return.getHours() : '0'+date_to_return.getHours())+
    ':'+ (date_to_return.getMinutes() > 9 ? date_to_return.getMinutes() : '0'+date_to_return.getMinutes());
  }

  async setQuestionTypes(){
    this.question_types = [
      {value: 'text' ,label: 'texto breve'},
      {value: 'date' ,label: 'Fecha'},
      {value: 'time' ,label: 'Hora (Beta)'},
      {value: 'number' ,label: 'Número'},
      {value: 'phone' ,label: 'Número teléfonico'},
      {value: 'textarea' ,label: 'Texto largo'},
      {value: 'email' ,label: 'Correo electrónico'},
      {value: 'photo' ,label: 'Tomar foto (Beta)'},
      {value: 'gps' ,label: 'Ubicación (Beta)'},
      {value: 'select' ,label: 'Lista desplegable'},
      {value: 'checklist' ,label: 'Casilla de verificación'},
      {value: 'radiogroup' ,label: 'Opción múltiple'}
    ];
  }

  async setMonths() {
    this.months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
  }

  async getAnswersList(){
    this.viewAnswersListId = "div-loading"

    this.data.getSaveData()
    .subscribe(
      async Response => {
        console.log('Response.data');
        console.log(Response.data);
        //this.task_list = Response.data
        this.answers_list = Response.data;
        console.log('this.answers_list');
        console.log(this.answers_list);
        this.viewAnswersListId = "view-answers-list-id";
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener las respuestas');
        this.viewAnswersListId = "view-answers-list-id";
      }
    )
  }

  async downloadAnswersList(){
    //this.viewAnswersListId = "div-loading"

    this.data.getSaveData()
    .subscribe(
      async Response => {
        console.log('Response.data');
        console.log(Response.data);
        window.open(URL.createObjectURL(
          new Blob([JSON.stringify(Response.data)], {
            type: 'application/binary'}
          )
      ))
        //this.viewAnswersListId = "view-answers-list-id";
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener las respuestas');
        //this.viewAnswersListId = "view-answers-list-id";
      }
    )
  }

  async getTasks(){
    this.viewTaskListId = "div-loading";
    this.titleComplement = ""
    this.task_list = []
    this.raw_task_list = []
    this.task.getTasks()
    .subscribe(
      async Response => {
        console.log(Response.data);
        //this.task_list = Response.data
        this.raw_task_list = Response.data;

        this.raw_task_list = this.raw_task_list.map(item =>{
          item['show_body'] = false;
          item['card_icon_up_down'] = 'lnr lnr-chevron-down-circle';
          return item;
        })

        

        this.groupItems()
        this.viewTaskListId = "view-task-list-id";

        
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener las tareas');
        this.viewTaskListId = "view-task-list-id";
      }
    )
  }

  groupItems(){
    const tmp_template_list = this.raw_task_list;
    var cont = 0;
    var list_par = [];
    for (let item of tmp_template_list) {
      if(cont==0){
        list_par = []
      }

      list_par.push(item);

      if(cont==1){
        this.task_list.push(list_par);
      }

      if (cont==1){
        cont = 0
      }else{
        cont = cont +1;
      }
    }
    console.log(this.task_list);
  }

  async showBodyCard(itemId){
    console.log(itemId);
    this.raw_task_list = this.raw_task_list.map(item =>{
      console.log(item);
      if(item.id === itemId){
        if (item.show_body){
          item.show_body = false
          item['card_icon_up_down'] = 'lnr lnr-chevron-down-circle';
        }else{
          item.show_body = true
          item['card_icon_up_down'] = 'lnr lnr-chevron-up-circle';
        }
        return item
      }else{
        return item
      }
    } 
    )

    this.groupItems()

  }

  async createTask(){
    console.log();
    let new_task = {};

    new_task['name'] = this.task_to_create['name'];
    new_task['from_group_area'] = this.from_group_area;

    this.task.createTask(new_task)
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.showSnackbarAction('Tarea creada');
        let task_id = Response.data['id'];
        this.viewTaskDetail(task_id);
      },
      async ResponseError => {
        console.log('error al obtener los perfiles');
        console.log(ResponseError);
        this.showSnackbarAction('error al crear la tarea');
      }
    )
  }

  async viewTaskDetail(idTask){
    this.titleComplement = "/detalle de tarea"
    console.log('viewTaskDetail ',idTask)
    this.viewList = false;
    this.viewDetail = true;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.task.getTask(idTask)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response.data);
          this.task_detail = Response.data
          console.log(this.task_detail);
        },
        async ResponseError => {
          console.log('error al obtener el detalle de la tarea')
          console.log(ResponseError);
          this.showSnackbarAction('Error al obtener el detalle de la tarea');

        }
      )
  }

  async publishTask(idTask){
    this.task.publishTask(idTask)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response.data);
          let message = Response.message
          this.showSnackbarAction(message);
          this.viewTaskDetail(idTask);
        },
        async ResponseError => {
          console.log('Error al publicar la tarea')
          console.log(ResponseError);
          this.showSnackbarAction('Error al publicar la tarea');

        }
      )
  }

  async stopTask(idTask){
    this.task.stopTask(idTask)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response.data);
          let message = Response.message
          this.showSnackbarAction(message);
          this.viewTaskDetail(idTask);
        },
        async ResponseError => {
          console.log('Error al pausar la tarea')
          console.log(ResponseError);
          this.showSnackbarAction('Error al pausar la tarea');

        }
      )
  }

  async openConfirmDeleteProfileDialog(idTask) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent,{
      data:{
        message: ('¿Esta seguro de eliminar esta tarea?'),
        buttonText: {
          cancel: 'Cancelar',
          ok: 'Eliminar'
        }
      }
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.task.deleteTask(idTask)
        .subscribe(
          async Response => {
            let message = Response.message
            this.showSnackbarAction(message);
            this.goToPrincipal();
          },
          async ResponseError => {
            console.log('Error al eliminar la tarea')
            console.log(ResponseError);
            this.showSnackbarAction('Error al eliminar la tarea');
          }
        )
      }
    });
  }

  async goToPrincipal(){
    this.titleComplement = ""
    this.viewList = true;
    this.viewDetail = false;
    this.viewCreate = false;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;
    this.getTasks();
  }

  async goToCreate(){
    this.titleComplement = "/creación de tarea"
    console.log('create task')
    this.viewList = false;
    this.viewDetail = false;
    this.viewCreate = true;
    this.viewEdit = false;
    this.viewDeleteConfirmation = false;

    this.task_to_create = {};

    this.task_to_create['name'] = '';

    this.from_group_area = [];
  }

  async addNewArea(){
    this.seq_area_id += 1;
    let new_area = {};

    new_area['id_seq'] = this.seq_area_id;
    new_area['title'] = '';
    new_area['description'] = '';
    new_area['questions'] = [];
    new_area['index_questions'] = 0;
    this.from_group_area.push(new_area);
  }

  async deleteArea(indet_to_remove){
    this.from_group_area.splice(indet_to_remove,1);
  }

  async addQuestionToArea(area){
    let question_to_add = {};

    question_to_add['id_seq'] = area['index_questions'];
    question_to_add['label'] = '';
    question_to_add['type'] = 'text';
    question_to_add['required'] = false;
    question_to_add['options'] = [];
    question_to_add['index_options'] = 0;

    area['questions'].push(question_to_add);

    console.log(area['questions'])

    area['index_questions'] += 1;

  }

  async removeQuestionOfArea(area, indet_to_remove){
    area['questions'].splice(indet_to_remove,1);
  }

  async addOptionsToQuestion(question){
    let optionToAdd = {};

    optionToAdd['id_seq'] = question['index_options'];
    optionToAdd['label'] = '';

    question['options'].push(optionToAdd);

    question['index_options']+= 1;
  }

  async removeOptionOfQuestion(question, indet_to_remove){
    question['options'].splice(indet_to_remove,1);
  }

  allowPerision(idPermision){
    let actions = [];
    actions = this.menu.actions

    let finded = actions.find(item => { return item.action_id == idPermision && item.active})

    let value_to_return = false;
    if(finded){
      value_to_return = true;
    }else{
      value_to_return = false;
    }

    return value_to_return;

  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

  initNewTask(){
    if (this.newTemplateTaskChild != undefined){
      this.newTemplateTaskChild.ngOnInit();
    }
  }

  openModalNewTempateTask(){
    //this.showModalNewTempateTask = !this.showModalNewTempateTask;
    this.newTemplateTaskChild.showModalNewTempateTask = true;
    this.newTemplateTaskChild.initNewTask();
  }
}
