import { Component, OnInit } from '@angular/core';
import {MenuService} from '../menu.service';
import {TasksService} from '../tasks.service';
import { EnterDataService } from '../enter-data.service';

import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog"
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ValueTransformer } from '@angular/compiler/src/util';


@Component({
  selector: 'app-enter-data',
  templateUrl: './enter-data.component.html',
  styleUrls: ['./enter-data.component.css']
})
export class EnterDataComponent implements OnInit {
  titleComplement: String = "";
  viewList: Boolean = true;
  viewDetail: Boolean = false;

  allowEnterData: Boolean = false;


  task_list: any[] = [];
  task_detail: {};
  task_to_create: {};

  question_types: any[] = [];

  task_status: {}

  
  months: any[] = [];

  constructor(private task: TasksService,
    private menu: MenuService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private enterData: EnterDataService) { }

  ngOnInit(): void {
    this.goToPrincipal();

    this.allowEnterData = this.allowPerision('71f79f27-f166-4ba6-bdef-f53fbec6127d');
    
    this.setTaskStatus();
    this.setMonths();
    

  }

  async setTaskStatus(){
    this.task_status = {
      CREATED: 'creada',
      PUBLISHED: 'publicada',
      STOPPED: 'detenida',
      DELETED: 'eliminada'
    }
  }

  returnTaskStatus(status){
    return this.task_status[status];
  }

  returnTaskCerationDate(creation_timestamp){

    let unix_timestamp = creation_timestamp
    let date_to_return = new Date(unix_timestamp * 1000);

    return  date_to_return.getDate()+
    ' de '+this.months[date_to_return.getMonth()]+
    ' de '+date_to_return.getFullYear()+
    ' '+ (date_to_return.getHours() > 9 ? date_to_return.getHours() : '0'+date_to_return.getHours())+
    ':'+ (date_to_return.getMinutes() > 9 ? date_to_return.getMinutes() : '0'+date_to_return.getMinutes());
  }

  async setMonths() {
    this.months = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];
  }

  async getPublishedTasks(){
    this.titleComplement = ""
    this.task_list = []
    this.task.getPublishedTasks()
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.task_list = Response.data
      },
      async ResponseError => {
        console.log(ResponseError);
        this.showSnackbarAction('error al obtener las tareas publicadas');
      }
    )
  }

  async saveData(){
    console.log(this.task_detail);
    this.enterData.saveData(this.task_detail)
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.showSnackbarAction('Datos registrados con exito');
        this.goToPrincipal();
      },
      async ResponseError => {
        console.log('error al obtener los perfiles');
        console.log(ResponseError);
        this.showSnackbarAction('error al crear la tarea');
      }
    )
  }

  async viewTaskDetail(idTask){
    this.titleComplement = ""
    console.log('viewTaskDetail ',idTask)
    this.viewList = false;
    this.viewDetail = true;
    this.task.getTask(idTask)
      .subscribe(
        async Response => {
          console.log(Response.statusCode)
          console.log(Response.data);
          this.task_detail = await this.setDefaultValues(Response.data);
          console.log(this.task_detail);
        },
        async ResponseError => {
          console.log('error al obtener el detalle de la tarea')
          console.log(ResponseError);
          this.showSnackbarAction('Error al obtener el detalle de la tarea');

        }
      )
  }

  async setDefaultValues(formData){
    let data_with_values = {};
    data_with_values = formData;
    
    data_with_values['from_group_area'] = data_with_values['from_group_area'].map(function(item){
      let new_item = {};

      new_item['id'] = item['id'];
      new_item['title'] = item['title'];
      new_item['description'] = item['description'];

      new_item['questions'] = item['questions'].map(function(item){
        let new_item = {};

        let type = item['type'];

        new_item['id'] = item['id'];
        new_item['label'] = item['label'];
        new_item['type'] = item['type'];
        new_item['required'] = item['required'];

        if(type == 'text' || type == 'date' || type == 'time' ||
            type == 'number' || type == 'phone' || type == 'textarea' ||
            type == 'email'){
          new_item['value'] = '';
        }else if(type == 'select' || type == 'radiogroup'){
          console.log(item['options']);
          new_item['options'] = item['options'];
          new_item['value'] = null;
        }else if(type == 'checklist'){
          new_item['options'] = item['options'].map(function(item){
            let new_item = {};

            new_item['id'] = item['id'];
            new_item['label'] = item['label'];
            new_item['value'] = false;

            return new_item;
          })
        }

        return new_item;
      })



      return new_item;
    });
    console.log(data_with_values)
    return data_with_values;
  }

  async goToPrincipal(){
    this.titleComplement = ""
    this.viewList = true;
    this.viewDetail = false;
    this.getPublishedTasks();
  }

  allowPerision(idPermision){
    let actions = [];
    actions = this.menu.actions

    let finded = actions.find(item => { return item.action_id == idPermision && item.active})

    let value_to_return = false;
    if(finded){
      value_to_return = true;
    }else{
      value_to_return = false;
    }

    return value_to_return;

  }

  showSnackbarAction(content) {
    let snack = this.snackBar.open(content, 'OK');
    snack.afterDismissed().subscribe(() => {
      console.log("This will be shown after snackbar disappeared");
    });
    snack.onAction().subscribe(() => {
      console.log("This will be called when snackbar button clicked");
    });
  }

}

