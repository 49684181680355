import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { AuthService } from '../auth.service';



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public sideMenuIsOpen: boolean = true;
  public userNames: String = '';

  label_name: String = '';
  profile_name: String = '';

  constructor(private router:Router,
    private shared : SharedService,
    private auth : AuthService) { }

  ngOnInit(): void {
    this.shared.isOpenSideMenu$.subscribe((value) => {
      this.sideMenuIsOpen = value;
    });

    this.get_user_name()

  }

  //
  async logoutNavbar(){
    console.log("entro al logout");
    await this.shared.closeSideMenu();
    await localStorage.removeItem('access_token');
    //await this.router.navigate(['/login']);
    await window.location.reload();
  }

  openSideMenu = () => {
    this.shared.openSideMenu();
    console.log('this.sideMenuIsOpen',this.sideMenuIsOpen)
  }


  ngOnChanges() {
    // Este método se llama cuando el valor de @Input valorEntrada cambia.
    console.log('this.sideMenuIsOpen desde navbar',this.sideMenuIsOpen);
  }

  async get_user_name(){
    let user_json_data = JSON.parse(this.auth.userData)
    this.label_name = user_json_data.names
    this.profile_name = user_json_data.profile_name
  }
  
}
