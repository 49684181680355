<div class="warp">
    <div class="group-page">
        <h1 class="title-page">Administración de usuarios{{titleComplement}}</h1>
    </div>
    <div *ngIf="viewList">
        <div class="group-page align-right">
            <button class="button-blue" (click)="goToCreate()" *ngIf="allowCreateUsers">
                Nuevo usuario
            </button>
        </div>
        <div class="group-page" *ngIf="allowViewUsers">
            <table class="table">
                <thead>
                    <th>Usuario</th>
                    <th>Nombre</th>
                    <th>Email</th>
                    <th></th>
                </thead>
                <tbody>
                        <tr *ngFor="let userItem of user_list">
                            <td>{{userItem.username}}</td>
                            <td>{{userItem.names + " " +userItem.surnames}}</td>
                            <td>{{userItem.email}}</td>
                            <td class="align-right">
                                <button class="button-blue" (click)="viewUserDetail(userItem.id)">
                                    Detalle
                                </button>
                            </td>
                        </tr>            
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="viewCreate">
        <div class="group-page">
            <h2>Usuario</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_create.username" 
                name="userNames" placeholder="Usuario" required="required"/>
        </div>
        <div class="group-page">
            <h2>Password</h2>
            <input class="input" type="password"
                [(ngModel)]="newPasswordForUserToCreate" 
                name="passwordNewUser" placeholder="Password" required="required"/>
        </div>

        

        <div class="group-page">
            <h2>Nombres</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_create.names" 
                name="userNames" placeholder="Nombres" required="required"/>
        </div>
        <div class="group-page">
            <h2>Apellidos</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_create.surnames" 
                name="userSurnames" placeholder="Apellidos" required="required"/>
        </div>
        <div class="group-page">
            <h2>Correo electrónico</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_create.email" 
                name="userEmail" placeholder="Correo electrónico" required="required"/>
        </div>
        <div class="group-page">
            <h2>Perfil</h2>
            <div class="control" *ngFor="let profile of list_profile_for_user_to_create">
                <label class="radio">
                    <input type="radio" name="profile_for_user_to_create" (click)="selectedProfileForUserToCreate = profile.id" [checked]="profile.id === selectedProfileForUserToCreate">
                    {{profile.name}}
                </label>
            </div>

        </div>
        <div class="group-button">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="button-blue" (click)="createProfile()">
                Crear usuario
            </button>
        </div>
    </div>

    <div *ngIf="viewDetail">
        <div class="group-page">
            <h2>Usuario</h2>
            <span>{{user_detail.username}}</span>
        </div>
        <div class="group-page">
            <h2>Nombres</h2>
            <span>{{user_detail.names}}</span>
        </div>
        <div class="group-page">
            <h2>Apellidos</h2>
            <span>{{user_detail.surnames}}</span>
        </div>
        <div class="group-page">
            <h2>Correo electrónico</h2>
            <span>{{user_detail.email}}</span>
        </div>
        <div class="group-page">
            <h2>Perfil</h2>
            <span>{{user_detail.profile.name}}</span>
        </div>

        <div class="group-button">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="button-red" (click)="openConfirmDeleteUserDialog(user_detail.id)" *ngIf="allowDeleteUser">
                Eliminar
            </button>
            <button class="button-blue" (click)="toEditUserDetail(user_detail.id)" *ngIf="allowEditUsers">
                Editar
            </button>
        </div>
    </div>

    <div *ngIf="viewEdit">
        <div class="group-page">
            <h2>Usuario</h2>
            <span>{{user_to_edit.username}}</span>
        </div>
        <div class="group-page">
            <h2>Nombres</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_edit.names" 
                name="userNames" placeholder="Nombres" required="required"/>
        </div>
        <div class="group-page" *ngIf="user_to_edit.surnames != ''">
            <h2>Apellidos</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_edit.surnames" 
                name="userSurnames" placeholder="Apellidos" required="required"/>
        </div>
        <div class="group-page">
            <h2>Correo electrónico</h2>
            <input class="input" type="text"
                [(ngModel)]="user_to_edit.email" 
                name="userEmail" placeholder="Correo electrónico" required="required"/>
        </div>
        <div class="group-page">
            <h2>Perfil</h2>
            <div class="control" *ngFor="let profile of list_profile_for_user">
                <label class="radio">
                    <input type="radio" name="profile_for_user" (click)="selectedProfileToEdit = profile.id" [checked]="profile.id === selectedProfileToEdit">
                    {{profile.name}}
                </label>
            </div>

        </div>
        <div class="group-button">
            <button class="button-white" (click)="goToDetail()">
                Cancelar
            </button>
            <button class="button-blue" (click)="saveChangesUserDetail()">
                Guardar Cambios
            </button>
        </div>
    </div>

</div>