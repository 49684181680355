import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class ProfilesService {

  constructor(private http: HttpService) { }

  getProfiles(){
    return this.http.get('/profiles', {})
  }

  getProfile(idProfile){
    return this.http.get('/profiles/'+idProfile, {})
  }

  createProfile(data){
    return this.http.post('/profiles', data)
  }

  updateProfile(idProfile, data){
    return this.http.put('/profiles/'+idProfile, data)
  }

  deleteProfile(idProfile){
    return this.http.delete('/profiles/'+idProfile);
  }

  getActions(){
    return this.http.get('/profiles/actions', {});
  }
}
