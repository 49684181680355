import { Component, Input, OnInit,Output,EventEmitter } from '@angular/core';
import {TasksService} from '../../tasks.service';

@Component({
  selector: 'app-new-template-task',
  templateUrl: './new-template-task.component.html',
  styleUrls: ['./new-template-task.component.css']
})
export class NewTemplateTaskComponent implements OnInit {

  showModalNewTempateTask: Boolean = false;

  @Output("reloadTasks") reloadTasks: EventEmitter<any> = new EventEmitter();

  classCreateButton = "button-blue";
  buttonCreateText = "";

  newTask: {} = {
    name: null,
    from_group_area: [
      {
        title: null,
        description: null,
        questions: [],
        index_questions: 1
      }
    ]
  };

  question_types: any[] = [
    {value: 'text' ,label: 'texto breve'},
    {value: 'date' ,label: 'Fecha'},
    {value: 'time' ,label: 'Hora (Beta)'},
    {value: 'number' ,label: 'Número'},
    {value: 'phone' ,label: 'Número teléfonico'},
    {value: 'textarea' ,label: 'Texto largo'},
    {value: 'email' ,label: 'Correo electrónico'},
    {value: 'photo' ,label: 'Tomar foto (Beta)'},
    {value: 'gps' ,label: 'Ubicación (Beta)'},
    {value: 'select' ,label: 'Lista desplegable'},
    {value: 'checklist' ,label: 'Casilla de verificación'},
    {value: 'radiogroup' ,label: 'Opción múltiple'}
  ];

  constructor(private task: TasksService) { }


  ngOnInit(): void {
    this.classCreateButton = "button-blue";
    this.buttonCreateText = "Guardar";
    this.initNewTask();
  }

  active : String = 'is-active';

  closeModalNewTempateTask(){
    this.showModalNewTempateTask = false;
  }

  initNewTask(){
    this.newTask['name'] = '';
    let new_area = {};

    new_area['id_seq'] = 1;
    new_area['title'] = 'Default area';
    new_area['description'] = '';
    new_area['questions'] = [];
    new_area['index_questions'] = 0;
    this.newTask['from_group_area'][0] = new_area;

    console.log('new task area');
    console.log(this.newTask);
  }

  async addQuestionToArea(area){

    let question_to_add = {};

    question_to_add['id_seq'] = area['index_questions'];
    question_to_add['label'] = '';
    question_to_add['type'] = 'text';
    question_to_add['required'] = false;
    question_to_add['options'] = [];
    question_to_add['index_options'] = 0;

    area['questions'].push(question_to_add);

    console.log(area['questions'])

    area['index_questions'] += 1;

  }

  async setQuestionTypes(){
    this.question_types = [
      {value: 'text' ,label: 'texto breve'},
      {value: 'date' ,label: 'Fecha'},
      {value: 'time' ,label: 'Hora (Beta)'},
      {value: 'number' ,label: 'Número'},
      {value: 'phone' ,label: 'Número teléfonico'},
      {value: 'textarea' ,label: 'Texto largo'},
      {value: 'email' ,label: 'Correo electrónico'},
      {value: 'photo' ,label: 'Tomar foto (Beta)'},
      {value: 'gps' ,label: 'Ubicación (Beta)'},
      {value: 'select' ,label: 'Lista desplegable'},
      {value: 'checklist' ,label: 'Casilla de verificación'},
      {value: 'radiogroup' ,label: 'Opción múltiple'}
    ];
  }

  async addOptionsToQuestion(question){
    let optionToAdd = {};

    optionToAdd['id_seq'] = question['index_options'];
    optionToAdd['label'] = '';

    question['options'].push(optionToAdd);

    question['index_options']+= 1;
  }

  async removeQuestionOfArea(area, indet_to_remove){
    area['questions'].splice(indet_to_remove,1);
  }

  async removeOptionOfQuestion(question, indet_to_remove){
    question['options'].splice(indet_to_remove,1);
  }

  async createTask(){
    this.classCreateButton = "button-blue is-loading";
    this.buttonCreateText = "";
    console.log();
    let new_task = {};

    new_task['name'] = this.newTask['name'];
    new_task['from_group_area'] = this.newTask['from_group_area'];

    this.task.createTask(new_task)
    .subscribe(
      async Response => {
        console.log(Response.data);
        this.reloadTasks.emit();
        this.closeModalNewTempateTask();
        this.classCreateButton = "button-blue";
        this.buttonCreateText = "Guardar";
      },
      async ResponseError => {
        console.log('error al obtener los perfiles');
        console.log(ResponseError);
        this.classCreateButton = "button-blue";
        this.buttonCreateText = "Guardar";
      }
    )
  }

}
