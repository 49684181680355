<div class="warp">
    <div class="group-page">
        <h1 class="title-page">Administración de perfiles{{titleComplement}}</h1>
    </div>
    <div *ngIf="viewList">
        <div class="group-page align-right">
            <button class="button-blue" (click)="goToCreate()" *ngIf="allowCreateProfiles">
                Nuevo perfil
            </button>
        </div>
        <div class="group-page" *ngIf="allowViewProfiles">
            <table class="table">
                <thead>
                    <th>Nombre del perfil</th>
                    <th></th>
                </thead>
                <tbody>
                        <tr *ngFor="let profileItem of profile_list">
                            <td>{{profileItem.name}}</td>
                            <td class="align-right">
                                <button class="button-blue" (click)="viewProfileDetail(profileItem.id)">
                                    Detalle
                                </button>
                            </td>
                        </tr>            
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="viewCreate">
        <div class="group-page">
            <h2>Nombre de perfil</h2>
            <input class="input" type="text"
            [(ngModel)]="profile_to_create.name" 
            name="profileName" placeholder="Nombre del perfil" required="required"/>
        </div>
        <h2>Permisos</h2>
        <div class="group-page" *ngFor="let action of profile_to_create.actions">
            <input type="checkbox"
                [checked]="action.active"
                [(ngModel)]="action.active"
            />
            <label >{{action.label}}</label>
        </div>
        <div class="group-button">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <!--             <button class="button-blue" (click)="createProfile()"> -->
            <button class="button-blue" (click)="createProfile()">
                Crear perfil
            </button>
        </div>
    </div>

    <div *ngIf="viewDetail">
        <div class="group-page">
            <h2>Nombre de perfil</h2>
            <span>{{profile_detail.name}}</span>
            
            
        </div>
        <h2>Permisos</h2>
        <div class="group-page" *ngFor ="let action of profile_detail.actions">
            <span *ngIf="action.active">{{action.label}}</span>
        </div>
        <div class="group-page align-right">
            <button class="button-white" (click)="goToPrincipal()">
                Cancelar
            </button>
            <button class="custom-delete-btn" (click)="openConfirmDeleteProfileDialog(profile_detail.id)" *ngIf="allowDeleteProfiles">
                Eliminar
            </button>
            <button class="button-blue" (click)="toEditProfileDetail(profile_detail.id)" *ngIf="allowEditProfiles">
                Editar
            </button>
        </div>
    </div>

    <div *ngIf="viewEdit">
        <div class="group-page">
            <h2>Nombre de perfil</h2>
            <input class="input" type="text"
            [(ngModel)]="profile_to_edit.name" 
            name="profileName" placeholder="Nombre del perfil" required="required"/>
        </div>
        <h2>Permisos</h2>
        <div class="group-page" *ngFor="let action of profile_to_edit.actions">
            <input type="checkbox"
                [checked]="action.active"
                [(ngModel)]="action.active"
            />
            <label >{{action.label}}</label>
        </div>
        <div class="group-button">
            <button class="button-white" (click)="goToDetail()">
                Cancelar
            </button>
            <button class="button-blue" (click)="saveChangesProfileDetail()">
                Guardar Cambios
            </button>
        </div>
    </div>
</div>
