import { Component, OnInit  } from '@angular/core';
import { Router } from '@angular/router';
import {AuthService} from './auth.service';
import { SharedService } from './shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'ng-proyect';
  loggedUser: Boolean = false;
  accessToken: String = '';

  label_name: String = ''

  private isOpenSideMenu: boolean = true;

  ngOnInit(): void {
    this.router.navigate(['/home']);
    this.getToken()
    this.get_user_name()
        
    this.shared.isOpenSideMenu$.subscribe((value) => {
      this.isOpenSideMenu = value;
    });
  }

  constructor(private router: Router,
    private auth: AuthService,
    private shared: SharedService) {
  }

  async getToken(){
    //this.accessToken = localStorage.getItem('access_token');
    this.accessToken = this.auth.accessToken

    console.log(this.accessToken)

    if (this.accessToken == null || this.accessToken == undefined || this.accessToken == ''){
      this.loggedUser = false;
    }else{
      this.loggedUser = true;
    }
  }

  async get_user_name(){
    let user_json_data = JSON.parse(this.auth.userData)
    this.label_name = user_json_data.names
  }

  async logout(){
    await this.shared.closeSideMenu();
    await localStorage.removeItem('access_token');
    //await this.router.navigate(['/login']);
    await window.location.reload();
  }

  openSideMenu() {
    this.shared.openSideMenu();
  }

}
