import { Component, OnInit,Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import {MenuService} from '../menu.service';
import { Router } from '@angular/router';
import { SharedService } from '../shared.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  MenuList: any[] = [];

  sideMenuIsOpen : boolean = true;

  loading_menu : boolean = true;

  constructor(private menu: MenuService,
    private router: Router,
    private shared: SharedService) { }


  ngOnInit(): void {
    this.getMenu();
    this.shared.isOpenSideMenu$.subscribe((value) => {
      this.sideMenuIsOpen = value;
    });
  }  

  async getMenu(){
    this.loading_menu = true;
    this.menu.getMenu()
      .subscribe(
        async Response => {
          this.MenuList = Response.menu_data;

          this.menu.menu = this.MenuList;

          let actions = this.MenuList.map( itemMenu =>{ return itemMenu.actions});

          let flatArrayActions = [].concat.apply([], actions)

          console.log(flatArrayActions);

          this.menu.actions = flatArrayActions;
          console.log(this.MenuList)
          this.loading_menu = false;
        },
        async ResponseError => {
          console.log('error'+ResponseError.message)
        }
      )
  }

  async selectItem(menuItem){
    this.MenuList = this.MenuList.map(function(item){
      if(menuItem.path == item.path){
        item['selected'] = true ;
      }else{
        item['selected'] = false ;
      }
      return item;
    })
    console.log(this.MenuList);
  }

  closeSideMenu() {
    this.shared.closeSideMenu();
  }

}
