import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor() { }

  private isOpenSideMenu = new Subject<boolean>();
  isOpenSideMenu$ = this.isOpenSideMenu.asObservable();

  openSideMenu() {
    this.isOpenSideMenu.next(true);
  }

  closeSideMenu() {
    this.isOpenSideMenu.next(false);
  }

}
