import { Injectable } from '@angular/core';
import { HttpService } from "./http.service";


@Injectable({
  providedIn: 'root'
})
export class EnterDataService {

  constructor(private http: HttpService) { }

  saveData(data){
    return this.http.post('/enter-data/', data)
  }

  getSaveData(){
    return this.http.get('/enter-data/')
  }
}