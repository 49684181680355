<div class="modal " [ngClass]="showModalNewTempateTask ? 'is-open': 'is-close'">
    <div class="modal-background"></div>
    <div class="modal-panel" *ngIf="newTask">
        <div class="wrap">
            <div class="close-button" (click)="closeModalNewTempateTask()">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1495_19068)">
                    <path d="M28.5 9.615L26.385 7.5L18 15.885L9.615 7.5L7.5 9.615L15.885 18L7.5 26.385L9.615 28.5L18 20.115L26.385 28.5L28.5 26.385L20.115 18L28.5 9.615Z" fill="#48536A"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1495_19068">
                    <rect width="36" height="36" fill="white"/>
                    </clipPath>
                    </defs>
                    </svg>
                    
            </div>
            <div class="head-modal">
                <h1 class="title-modal">Agregar encuesta</h1>
                <label class="subtitle-gray">Titulo</label>
                <input class="input" type="text" [(ngModel)]="newTask.name">
                <label class="subtitle-gray">Descripcion</label>
                <input class="input" type="text" [(ngModel)]="newTask.from_group_area[0].description">
            </div>
            <hr/>
            <div *ngFor="let area of newTask.from_group_area;let indexOfArea=index;">
                <div class="question" *ngFor="let question of area.questions;let indexOfQuestion=index;">
                    <div class="wrap-question-label">
                        <label class="subtitle-gray">Pregunta</label>
                        <span class="lnr lnr-trash custom-delete-icon" (click)="removeQuestionOfArea(area, indexOfQuestion)"></span>
                    </div>
                    <input class="input"
                        type="text"
                        [(ngModel)]="question.label">
                    <label class="subtitle-gray">Tipo de pregunta</label>
                    <div class="select">
                        <select [(ngModel)]="question.type" class="question-selected-type">
                            <option *ngFor="let question_type of question_types" [value]="question_type.value">
                                {{question_type.label}}
                            </option>
                        </select>
                    </div>
                    <label class="subtitle-gray" *ngIf="question.type == 'checklist' || question.type == 'select' || question.type == 'radiogroup'">Opciones</label>
                    <div *ngIf="question.type == 'checklist' || question.type == 'select' || question.type == 'radiogroup'">
                        
                        <div class="group-page">
                            <table style="width: 100%;">
                                <tr *ngFor="let option of question.options;let indexOfOption=index;">
                                    <td style="vertical-align: baseline;">
                                        <input class="input"
                                            type="text"
                                            [(ngModel)]="option.label"
                                            placeholder="Opcion"
                                            required="required"/>
                                    </td>
                                    <td class="custom-delete-icon-td">
                                        <span class="lnr lnr-trash custom-delete-icon" (click)="removeOptionOfQuestion(question,indexOfOption)"></span>
                                        <!--
                                        <button class="custom-delete-btn" (click)="removeOptionOfQuestion(question,indexOfOption)">
                                            Eliminar opción
                                        </button>
                                        -->
                                    </td>
                                </tr>
                            </table>
                            
                        </div>
                        <div class="add-more" (click)="addOptionsToQuestion(question)">
                            <p>+</p>
                        </div>
                    </div>
                    <hr/>
                </div>
                <div class="add-more" (click)="addQuestionToArea(area)">
                    <p >+</p>
                </div>
            </div>
            <div class="modal-panel-footer">
            </div>

            
            
            <div class="group-button">
                <button class="button-white" (click)="showModalNewTempateTask = false">Cancelar</button>
                <button class="{{classCreateButton}}" (click)="createTask()" >{{buttonCreateText}}</button>
            </div>
        </div>
    </div>
</div>